import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import {
  CLIENTS,
  ClientLable,
  FRAMEWORK,
  HOSTMexxar,
  TRUSTS,
} from "../../../configs/api-config";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import useDidMountEffect from "./intialRunUseEffect";
import { connect } from "react-redux";
import { countiesGetAction } from "../../../redux/actions/initialDataGetAction";
import { forEach } from "lodash";
import store from "../../../redux/store";
import Select from "react-select";
import notNull from "../../../util/common/notNull";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronUp,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Delete from "./delete";
import moment from "moment";
import UpdateHospitalNote from "./Admin/updateHospitalNote";
import ModalComponent from "../modalComponent";
import { UncontrolledTooltip } from "reactstrap/lib";

const NewClient = (props) => {
  const { closeModal, rowID, mode, success, counties, auth } = props;
  const MexxarApi = CLIENTS;
  const MexxarApiTrust = TRUSTS;
  const MexxarApiFramework = FRAMEWORK;

  const { addToast } = useToasts();

  const [trusts, setTrusts] = useState([]);
  const [county, setCounty] = useState([]);

  const [rowDatas, setRowDatas] = useState(null);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [frameworks, setFrameworks] = useState([]);
  const { register, handleSubmit, errors, reset } = useForm();
  const [contactPersons, setContactPersons] = useState([]);
  const [selectedTrustId, setSelectedTrustId] = useState(0);

  const [selectedCounty, setSelectedCounty] = useState({
    id: "",
    value: "",
    label: "",
  });

  const [selectedFrameworkId, setSelectedFrameworkId] = useState(0);

  const [selectedTrustIdError, setSelectedTrustIdError] = useState(false);
  const [selectedFrameworkError, setSelectedFrameworkError] = useState(false);
  const [selectedCountyError, setSelectedCountyError] = useState(false);
  const [errorPostCode, setErrorPostCode] = useState(false);
  const [showMoreAddressDetails, setShowMoreAddressDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // const toggleList = () => {
  //   setIsOpen(!isOpen);
  // };

  const checkPostalCode = (postalCode) => {
    axios.get(HOSTMexxar + "postal-codes/code/" + postalCode).then((res) => {
      if (res.data.body[0]) {
        setErrorPostCode(false);
        setSelectedCounty(res.data.body[0]?.county);
        setLatitude(res.data.body[0]?.latitude);
        setLongitude(res.data.body[0]?.longitude);
      } else {
        setErrorPostCode(true);
        setSelectedCounty("");
        setLatitude("");
        setLongitude("");
      }
    });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    // Allow only numbers and characters
    const filteredValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    setNewNote(filteredValue);
  };

  const onSubmit = (data, e) => {
    let phoneNumbers = [];

    for (let i = 0; i < 1000; i++) {
      if (!data["Cname" + i]) {
        break;
      }
      let name = data["Cname" + i];
      let phone = data["CPPhone" + i];

      phoneNumbers.push({
        name: name,
        phoneNumber: phone,
      });
    }

    let selectedTrustIdErrorTEMP = false;
    let selectedFrameworkErrorTEMP = false;
    let selectedCountyErrorTEMP = false;

    if (parseInt(data.trustId) == 0) {
      selectedTrustIdErrorTEMP = true;
      setSelectedTrustIdError(true);
    } else {
      selectedTrustIdErrorTEMP = false;
      setSelectedTrustIdError(false);
    }

    if (parseInt(data.frameworkId) == 0) {
      selectedFrameworkErrorTEMP = true;
      setSelectedFrameworkError(true);
    } else {
      selectedFrameworkErrorTEMP = false;
      setSelectedFrameworkError(false);
    }

    if (parseInt(data.county) == 0) {
      selectedCountyErrorTEMP = true;
      setSelectedCountyError(true);
    } else {
      selectedCountyErrorTEMP = false;
      setSelectedCountyError(false);
    }

    if (
      mode === "Add" &&
      !selectedTrustIdErrorTEMP &&
      !selectedFrameworkErrorTEMP &&
      !selectedCountyErrorTEMP
    ) {
      axios
        .post(MexxarApi, {
          name: data.name,
          email: data.email,
          primaryMobile: data.primaryMobile,
          secondaryMobile: data.secondaryMobile,
          houseNumber: data.houseNumber,
          streetName: data.streetName,
          city: data.city,
          state: data.county,
          postCode: data.postCode.toUpperCase(),
          frameworkId: parseInt(data.frameworkId),
          trustId: parseInt(data.trustId),
          latitude: data.latitude,
          longtitude: data.longitude,
          phoneNumbers: phoneNumbers,
          meritClientId: data?.meritClientId,
        })
        .then((res) => {
          if (res.data.status == "success") {
            success();
            ToastCommon(true, "success", addToast);
            e.target.reset();
            setRowDatas(null);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    } else if (
      mode === "Update" &&
      !selectedTrustIdErrorTEMP &&
      !selectedFrameworkErrorTEMP &&
      !selectedCountyErrorTEMP
    ) {
      axios
        .put(MexxarApi, {
          id: rowID,
          name: data.name,
          email: data.email,
          primaryMobile: data.primaryMobile,
          secondaryMobile: data.secondaryMobile,
          houseNumber: data.houseNumber,
          streetName: data.streetName,
          city: data.city,
          state: data.county,
          postCode: data.postCode.toUpperCase(),
          frameworkId: parseInt(data.frameworkId),
          trustId: parseInt(data.trustId),
          latitude: data.latitude,
          longtitude: data.longitude,
          phoneNumbers: phoneNumbers,
          meritClientId: data?.meritClientId,
        })
        .then((res) => {
          if (res.data.status == "success") {
            success();
            ToastCommon(true, "success", addToast);
            e.target.reset();
            setRowDatas(null);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const geTRowData = () => {
    axios
      .get(MexxarApi + "/" + rowID)
      .then((res) => {
        setRowDatas(res.data.body[0]);
        setContactPersons(res.data.body[0].phoneNumbers);
      })
      .catch((err) => {
        //console.log(err)
      });
  };
  const getFrameworks = () => {
    axios
      .get(MexxarApiFramework)
      .then((res) => {
        setFrameworks(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };
  const getTrusts = () => {
    axios
      .get(MexxarApiTrust)
      .then((res) => {
        setTrusts(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const removeMTAddMore = () => {
    contactPersons.pop(contactPersons);
    setContactPersons([...contactPersons]);
  };

  let filtered = contactPersons.filter(
    (item) => Object.keys(item).length === 0
  );

  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [rowNoteID, setRowID] = useState("");
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [rowvalue, setRowValue] = useState("");
  // const [reset, setReset] = useState(false);
  // const [rowID, setRowID] = useState(0);

  const [value, setValue] = useState("");
  const [status, setStatus] = useState("");
  const changeHandler = ({ target }) => {
    setValue(target.value);
  };
  const getNotes = () => {
    axios
      .get("https://permapi.mexxar.com/clientNote/client/" + rowID)
      .then((res) => {
        setNotes(res.data.body);
        console.log(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };
  const toggleList = () => {
    setIsOpen(!isOpen);
  };
  const deleteNote = () => {
    axios
      .delete("https://permapi.mexxar.com/clientNote/" + rowNoteID)
      .then((res) => {
        // getAllRows();
        getNotes();
        ToastCommon(true, "delete", addToast);
      })
      .catch((err) => {
        //console.log(err)
        //ToastCommon(true, "update", addToast);
      });
  };

  useEffect(() => {
    getNotes();
  }, []);

  const handleSubmit1 = () => {
    if (newNote.trim() === "") {
      ToastCommon(true, "error", addToast);
      return;
    }

    axios
      .post("https://permapi.mexxar.com/clientNote", {
        note: newNote,
        employee: auth.item?.id,
        clients: rowID,
      })
      .then((res) => {
        setStatus(res?.data?.status);
        if (res.data.status == "Success") {
          ToastCommon(true, "success", addToast);
          getNotes();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getTrusts();
    getFrameworks();
    if (mode == "Update") {
      geTRowData();
    }
    return () => {};
  }, []);

  useDidMountEffect(() => {
    if (mode === "Update" && rowDatas) {
      setSelectedTrustId(rowDatas.trust.id);
      setSelectedFrameworkId(rowDatas.framework.id);
      setSelectedCounty(rowDatas.state);
      setLatitude(rowDatas?.postalCode?.latitude);
      setLongitude(rowDatas?.postalCode?.longitude);
    } else {
      setSelectedTrustId(0);
      setSelectedFrameworkId(0);
    }
    return () => {};
  }, [rowDatas]);

  const reArrangeToShowInSelectDropDown = (data) => {
    let reArranged = [];
    forEach(data, function (item) {
      reArranged.push({
        value: item,
        label: item,
        id: item,
      });
    });
    return reArranged;
  };

  useEffect(() => {
    store.dispatch(countiesGetAction());
    return () => {};
  }, []);

  useEffect(() => {
    if (counties) {
      setCounty(reArrangeToShowInSelectDropDown(counties));
    }
    return () => {};
  }, [counties]);
  return (
    <Form className="col-12" onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={4}>
          {ClientLable} Name<span className="text-danger ml-1">*</span>
        </Label>
        <Col sm={7}>
          <Input
            type="text"
            // placeholder="name"
            defaultValue={rowDatas && rowDatas.name}
            name="name"
            id="name"
            invalid={errors.name}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          ></Input>

          {errors.name && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.name?.message}
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={4}>
          Email<span className="text-danger ml-1">*</span>
        </Label>
        <Col sm={7}>
          <Input
            type="email"
            // placeholder="Email"
            name="email"
            defaultValue={rowDatas && rowDatas.email}
            invalid={errors.email}
            innerRef={register({
              required: {
                value: true,
                message: "E-mail required",
              },
              pattern: {
                value:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email address!",
              },
            })}
          ></Input>
          {errors.email && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.email.message}
                <span className="text-danger ml-1">*</span>
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={4}>Merit Client Id</Label>
        <Col sm={7}>
          <Input
            type="text"
            defaultValue={rowDatas ? rowDatas?.meritClientId : ""}
            name="meritClientId"
            id="meritClientId"
            innerRef={register({})}
          ></Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={4}>
          {ClientLable} T.P. primary<span className="text-danger ml-1">*</span>
        </Label>
        <Col sm={7}>
          <Input
            type="text"
            // placeholder="T.P. primary"
            name="primaryMobile"
            id="primaryMobile"
            defaultValue={rowDatas && rowDatas.primaryMobile}
            invalid={errors.primaryMobile}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              pattern: {
                value:
                  /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                message: "Please enter a valid phone number",
              },
            })}
          ></Input>
          {errors.primaryMobile && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.primaryMobile.message}
                <span className="text-danger ml-1">*</span>
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>

      <hr />
      <Label>{ClientLable} Address</Label>
      {showMoreAddressDetails ? (
        <FontAwesomeIcon
          className="mx-2 pointer no-shadow"
          icon={faChevronCircleUp}
          onClick={() => setShowMoreAddressDetails(false)}
        />
      ) : (
        <FontAwesomeIcon
          className="mx-2 pointer no-shadow"
          icon={faChevronCircleDown}
          onClick={() => setShowMoreAddressDetails(true)}
        />
      )}
      {showMoreAddressDetails && (
        <>
          <FormGroup row>
            <Label sm={4}>
              Building No.
              {/* <span className="text-danger ml-1">*</span> */}
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="houseNumber"
                id="houseNumber"
                defaultValue={rowDatas && rowDatas.houseNumber}
                innerRef={register({ required: false, max: 35 })}
              ></Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>
              Street Name
              {/* <span className="text-danger ml-1">*</span> */}
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                // placeholder="address"
                name="streetName"
                id="streetName"
                defaultValue={rowDatas && rowDatas.streetName}
                innerRef={register({ required: false, max: 35 })}
              ></Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Locality</Label>
            <Col sm={7}>
              <Input
                type="text"
                // placeholder="Locality"
                name="locality"
                defaultValue={rowDatas && rowDatas.locality}
                id="locality"
                innerRef={register({ required: false, max: 35 })}
              ></Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>
              Post town
              {/* <span className="text-danger ml-1">*</span> */}
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                // placeholder="Post Town"
                name="city"
                id="city"
                defaultValue={rowDatas && rowDatas.city}
                innerRef={register({ required: false, max: 35 })}
              ></Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>{ClientLable} T.P. secondary</Label>
            <Col sm={7}>
              <Input
                type="text"
                // placeholder="T.P. secondary"
                invalid={errors.secondaryMobile}
                name="secondaryMobile"
                id="secondaryMobile"
                defaultValue={rowDatas && rowDatas.secondaryMobile}
                innerRef={register({
                  required: {
                    value: false,
                    message: "This field is required!",
                  },
                  pattern: {
                    value:
                      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                    message: "Please enter a valid phone number",
                  },
                })}
              ></Input>
              {errors.secondaryMobile && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.secondaryMobile.message}
                    <span className="text-danger ml-1">*</span>
                  </li>
                </ul>
              )}
            </Col>
          </FormGroup>
        </>
      )}
      <FormGroup row>
        <Label sm={4} for="postCode">
          Post Code <small className="text-danger">* Ex Format: EC1A 2BP</small>
        </Label>
        <Col sm={7}>
          <Input
            type="text"
            name="postCode"
            id="postCode"
            className="capitalizeUpperCase"
            placeholder="EC1A 2BP"
            onChange={(e) => checkPostalCode(e.target.value)}
            invalid={errors.postCode || errorPostCode}
            defaultValue={rowDatas && rowDatas?.postalCode?.code}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          ></Input>

          {errors.postCode && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.postCode?.message}
              </li>
            </ul>
          )}
          {errorPostCode && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                Invalid postal code format.
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={4}>
          County
          <span className="text-danger ml-1">*</span>
        </Label>
        <Col sm={7}>
          <Input
            type="select"
            id={"county"}
            name="county"
            value={selectedCounty}
            onChange={(e) => setSelectedCounty(e.target.value)}
            invalid={errors.county}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          >
            <option value={0}>Select</option>
            {county.map((items, id) => {
              return (
                <option key={id} value={items.value}>
                  {items.value}
                </option>
              );
            })}
          </Input>

          {selectedCountyError && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                This field is required!
                <span className="text-danger ml-1">*</span>
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>

      {/* <FormGroup row>
        <Label sm={4}>
          Postal code <span className="text-danger ml-1">*</span>
        </Label>
        <Col sm={7}>
          <Input
            type="text"
            // placeholder="Post Code"
            name="postCode"
            id="postCode"
            invalid={errors.postCode}
            defaultValue={rowDatas && rowDatas.postCode}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          />
          {errors.postCode && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.postCode.message}
                <span className="text-danger ml-1">*</span>
              </li>
            </ul>
          )}
        </Col>
      </FormGroup> */}
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label>
              Framework <span className="text-danger ml-1">*</span>
            </Label>

            <Input
              type="select"
              id="frameworkId"
              name="frameworkId"
              value={selectedFrameworkId}
              onChange={(e) => setSelectedFrameworkId(e.target.value)}
              invalid={errors.frameworkId}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              <option value={0}>Select</option>
              {frameworks.map((items, id) => {
                return (
                  <option key={id} value={items.id}>
                    {items.name}
                  </option>
                );
              })}
            </Input>
            {selectedFrameworkError && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  This field is required!
                  <span className="text-danger ml-1">*</span>
                </li>
              </ul>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Trust <span className="text-danger ml-1">*</span>
            </Label>
            <Input
              type="select"
              id="trustId"
              name="trustId"
              value={selectedTrustId}
              invalid={errors.trustId}
              onChange={(e) => setSelectedTrustId(e.target.value)}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              <option value={0}>Select</option>
              {trusts.map((items, id) => {
                return (
                  <option key={id} value={items.id}>
                    {items.name}
                  </option>
                );
              })}
            </Input>
            {selectedTrustIdError && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  This field is required!
                  <span className="text-danger ml-1">*</span>
                </li>
              </ul>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Latitude <span className="text-danger ml-1">*</span>
            </Label>

            <Input
              type="text"
              name="latitude"
              id="latitude"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              invalid={errors.latitude}
              defaultValue={rowDatas && rowDatas?.postalCode?.latitude}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            ></Input>
            {errors.latitude && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.latitude.message}
                  <span className="text-danger ml-1">*</span>
                </li>
              </ul>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Longitude <span className="text-danger ml-1">*</span>
            </Label>
            <Input
              type="text"
              name="longitude"
              id="longitude"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              defaultValue={rowDatas && rowDatas?.postalCode?.longitude}
              invalid={errors.longitude}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            ></Input>
            {errors.longitude && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.longitude.message}
                  <span className="text-danger ml-1">*</span>
                </li>
              </ul>
            )}
          </FormGroup>
        </Col>
      </Row>

      <hr />
      <div className="d-flex justify-content-between">
        <div>
          <Label>Contacting personnel</Label>
        </div>
        <div className="m-2">
          <Button
            color="success font-weight-bold mb-2"
            style={{ borderRadius: "50px" }}
            onClick={() => setContactPersons([...contactPersons, {}])}
          >
            +
          </Button>
          {filtered.length > 0 ? (
            <Button
              color="danger font-weight-bold mb-2 mx-2"
              style={{
                borderRadius: "80px",
                width: "40px",
                height: "40px",
              }}
              onClick={() => removeMTAddMore()}
            >
              -
            </Button>
          ) : null}
        </div>
      </div>
      {contactPersons.map((data, id) => {
        return (
          <Row form key={id}>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Name<span className="text-danger ml-1">*</span>
                </Label>

                <Input
                  type="text"
                  name={"Cname" + id}
                  defaultValue={data && data.name}
                  innerRef={register({ required: true, max: 15 })}
                ></Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Contact No<span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  type="text"
                  name={"CPPhone" + id}
                  defaultValue={data && data.phoneNumber}
                  innerRef={register({
                    required: {
                      value: false,
                      message: "This field is required!",
                    },
                    // pattern: {
                    //   value:
                    //     /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                    //   message: "Please enter a valid phone number",
                    // },
                  })}
                ></Input>
                {/* {console.log("errors angi",errors.`${"Cname" + id}`)} */}
                {/* {errors.primaryMobile && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.primaryMobile?.message}
                      </li>
                    </ul>
                  )} */}
              </FormGroup>
            </Col>
          </Row>
        );
      })}
      <Row className="justify-content-end">
        <div className="d-flex ">
          <Button className="mr-2" color="success" type="submit">
            {mode}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              closeModal();
              reset();
              setRowDatas(null);
            }}
          >
            Close
          </Button>
        </div>
      </Row>

      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteNote();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(!showDeleteRow)}
      />

      <ModalComponent
        show={showUpdateRow}
        header={`Update Note`}
        className={`modal-dialog modal-custom ${
          showUpdateRow ? "modal-dialog-centered" : ""
        }`}
        closeModal={() => setShowUpdateRow(false)}
      >
        <UpdateHospitalNote
          rowID={rowID}
          mode="Update"
          auth={auth?.item.id}
          rowvalue={rowvalue}
          rowNoteID={rowNoteID}
          action={getNotes}
          // success={() => {
          //   // setReset(!reset);
          //   setShowUpdateRow(false);
          // }}
          closeModal1={() => setShowUpdateRow(false)}
        />
      </ModalComponent>
    </Form>
  );
};

function mapStateToProps(state) {
  return {
    counties: state.initialDataGetReducer.counties,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(NewClient);
