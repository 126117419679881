import Axios from "axios";
import React, { useEffect, useState } from "react";
import "./hospitalsMap.style.css";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faClock, faCar } from "@fortawesome/free-solid-svg-icons";
import {
  REGISTER_CANDIDATE,
  POSTAL_CODES,
  CLIENTS,
  GoogleMapKey,
  ClientLable,
} from "../../configs/api-config";
import SliderComponent from "../../components/Slider/silder";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { isNull, filter, forEach } from "lodash";
import loadingAnimation from "../../../src/images/puff.svg";
import { Alert, Label } from "reactstrap";
import {
  consumeCredits,
  getCredits,
} from "../../util/common/creditTransactions";

const HospitalsMapOnboarding = (props) => {
  const { addToast } = useToasts();

  const { candidateId, postalCode, enable, mode } = props;

  const MexxarApi = REGISTER_CANDIDATE;
  const MexxarApiClients =
    CLIENTS +
    "/filter?offset=100&page=1" +
    `${postalCode?.county && "&pc_county=" + postalCode?.county}`;

  const MexxarApiPostal = POSTAL_CODES;
  const [places, setPlaces] = useState([]);
  const [finalResults, setFinalResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  //const [longitude, setLongitude] = useState(0.1278);
  // const [latitude, setLatitude] = useState(51.5074);
  const [longitude, setLongitude] = useState(postalCode?.longitude);
  const [latitude, setLatitude] = useState(postalCode?.latitude);
  //const [radius, setRadius] = useState(3000);
  //....later change this to radius ......///
  const [incomingRadius, setIncomingRadius] = useState(5);
  // console.log("radius.......",incomingRadius)
  const [zoom, setZoom] = useState(11);
  const [clients, setClients] = useState(null);
  const [hoverItemName, setHoverItemName] = useState(null);
  const [creditError, setCreditError] = useState({ bool: false, message: "" });

  const AnyReactComponent = ({ text }) => (
    // <div>
    //   <div style={{ width: 10, height: 10, background: "red" }}></div>
    //   {/* <p>{text}</p> */}
    // </div>
    <div>
      {hoverItemName == text.toUpperCase() ? (
        <FontAwesomeIcon
          size="3x"
          color="#e91e63"
          icon={faHospital}
        ></FontAwesomeIcon>
      ) : (
        <FontAwesomeIcon
          size="2x"
          color="#458AF7"
          icon={faHospital}
        ></FontAwesomeIcon>
      )}
    </div>
  );

  const getLongitudeLatitude = () => {
    isNull(postalCode)
      ? setLongitude(null)
      : setLongitude(Number(postalCode?.longitude));

    isNull(postalCode)
      ? setLatitude(null)
      : setLatitude(Number(postalCode?.latitude));
  };

  const getPlaces = () => {
    setLoading(true);
    Axios.get(
      mode === "individual"
        ? `${MexxarApi}/${candidateId}/nearest-clients?radius=${
            incomingRadius * 1000
          }`
        : `${MexxarApi}/nearest-clients?radius=${
            incomingRadius * 1000
          }&postalCode=${postalCode?.code}`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.results !== null) {
          let dests = "";
          let fin = [];
          let A = [];
          let B = [];
          res.data.results.map((item) => {
            A = filter(clients, (client) => {
              return item.name.toUpperCase() === client.value.toUpperCase();
            });

            if (A.length > 0) {
              dests += `${item.geometry.location.lat},${item.geometry.location.lng}|`;
              fin.push({
                name: A.length > 0 ? A[0].value : null,
                time: null,
                far: null,
                marketId: item.id,
              });
              //only show clients registered under the company
              B.push(item);
            }
          });

          setPlaces(B);
          getMiles(dests, fin);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  const getMiles = (desti, fin) => {
    // Axios.get(
    // encodeURI(
    //   `${MexxarApi}/${candidateId}/nearest-clients-distance?destinations=${desti}`
    // )

    consumeCredits(1, "CLIENT_MAP") //consuming cretis
      .then((result) => {
        if (result?.status === "Failed") {
          setCreditError({ bool: true, message: result?.message });
        } else if (result?.status === "Success") {
          setCreditError({ bool: false, message: "" });
        }
      })
      .catch((err) => {
        console.error("Error consuming credits:", err);
      });

    Axios.get(
      mode === "individual"
        ? encodeURI(
            `${MexxarApi}/${candidateId}/nearest-clients-distance?destinations=${desti}`
          )
        : encodeURI(
            `${MexxarApi}/nearest-clients-distance?destinations=${desti}&postalCode=${postalCode?.code}`
          )
    )

      // )
      .then((res) => {
        // console.log("getMiles", res);

        if (res.data.rows.length > 0) {
          //  console.log("empty value..............", key);

          let data = [...fin];
          res.data.rows[0].elements.map((item, i) => {
            //console.log("map inoming distance ", item);

            data[i].time = item.duration.text;
            data[i].far = item.distance.text;
            // data[i].farInKm = item.distance.value / 1000;
          });

          let tempData = [];
          forEach(data, function (item) {
            if (item.name != null) {
              tempData.push(item);
            }
          });
          setFinalResults(tempData);
        }
        // let data = [...fin];
        // res.data.rows[0].elements.map((item, i) => {
        //   //console.log("map inoming distance ", item);

        //   data[i].time = item.duration.text;
        //   data[i].far = item.distance.text;
        //   data[i].farInKm = item.distance.value / 1000;
        //   setFinalResults(data);
        // });
      });
  };

  const getClients = () => {
    axios
      .get(MexxarApiClients)
      .then((res) => {
        let tempArray = [];
        forEach(res.data.body.content, function (item) {
          tempArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });

        setClients(tempArray);
      })
      .catch((err) => {
        //console.log(err)
        //toastCommon(true, "error");
      });
  };

  useEffect(() => {
    if (enable) {
      getClients();
      getLongitudeLatitude();
    }
    return () => {};
  }, [incomingRadius, postalCode]);

  useDidMountEffect(() => {
    getCredits()
      .then((result) => {
        let creditResponse = result?.response;
        const clientMapCredits = creditResponse?.body?.filter(
          (item) => item.creditServiceType === "CLIENT_MAP"
        );
        if (clientMapCredits?.[0].creditBalance > 0) {
          setCreditError({
            bool: false,
            message: "",
            creditBalance: clientMapCredits?.[0]?.creditBalance,
          });
          if (enable) {
            getPlaces();
            getLongitudeLatitude();
          }
        } else {
          setCreditError({
            bool: true,
            message: "Credit error",
            creditBalance: 0,
          });
        }
      })
      .catch((err) => {
        console.error("Error consuming credits:", err);
      });

    return () => {};
  }, [clients]);

  const mouseEnter = (key, childProps) => {
    // const markerId = childProps.marker.get('id');
    // console.log("markerId", childProps);
    setHoverItemName(childProps.text.toUpperCase());
    // const index = this.props.markers.findIndex(m => m.get('id') === markerId);
    // if (this.props.onMarkerHover) {
    //   this.props.onMarkerHover(index);
    // }
  };

  const mouseLeave = (key, childProps) => {
    // const markerId = childProps.marker.get('id');
    //  console.log("markerId", childProps);
    setHoverItemName(null);
    // const index = this.props.markers.findIndex(m => m.get('id') === markerId);
    // if (this.props.onMarkerHover) {
    //   this.props.onMarkerHover(index);
    // }
  };

  return (
    <div className="card">
      <div className="mt-4" style={{ height: 400, width: "auto" }}>
        {creditError.bool ? (
          <Alert color="danger" className="p-2 mx-2">
            Please contact Mexxar to Recharge
            <br />
            Error: {creditError?.message} | Credit Balance:{" "}
            {creditError?.creditBalance}{" "}
          </Alert>
        ) : (
          ""
        )}

        {isNull(postalCode) ? (
          <div
            className=" d-flex align-items-center justify-content-center"
            style={{ height: 400, width: "100%" }}
          >
            <div>Please provide a postal code</div>
          </div>
        ) : (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: GoogleMapKey,
            }}
            center={{
              lat: latitude,
              lng: longitude,
            }}
            onChildMouseEnter={(key, childProps) => mouseEnter(key, childProps)}
            onChildMouseLeave={(key, childProps) => mouseLeave(key, childProps)}
            defaultZoom={zoom}
          >
            {places.map((item, i) => {
              return (
                <AnyReactComponent
                  key={i}
                  lat={item.geometry.location.lat}
                  lng={item.geometry.location.lng}
                  text={item.name}
                />
              );
            })}
          </GoogleMapReact>
        )}
      </div>
      <div className="p-2 mx-2 mt-4">
        <SliderComponent onStop={(data) => setIncomingRadius(data)} />
      </div>

      {finalResults?.length > 0 && (
        <div
          className="mx-2 "
          style={{
            maxHeight: 700,
            width: "100%",
            overflowY: "scroll",
            marginTop: "2rem",
          }}
        >
          {finalResults.map((item, i) => {
            return (
              <div
                key={i}
                className={`p-1 d-flex ${
                  hoverItemName == item.name.toUpperCase() ? "bg-primary" : null
                }`}
              >
                <div className="py-2  " style={{ width: "70px" }}>
                  <FontAwesomeIcon
                    color="#31c971"
                    icon={faClock}
                    className="mr-1 "
                  />
                  <small>{item.time}</small>
                </div>
                <div className="py-2" style={{ width: "70px" }}>
                  <FontAwesomeIcon
                    color="#f54394"
                    icon={faCar}
                  ></FontAwesomeIcon>

                  <small>{item.far}</small>
                </div>
                <div className="py-2" style={{ width: "20px" }}>
                  <FontAwesomeIcon color="#458AF7" icon={faHospital} />
                </div>
                <div className="py-2" style={{ width: "230px" }}>
                  <small>{item.name}</small>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {loading && (
        <div className="d-flex justify-content-center justify-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
      {!enable && (
        <div className="d-flex justify-content-center justify-items-center">
          <Label className="text-center p-3 d-flex align-items-center justify-content-center">
            The map is disabled. Please turn it on to see the {ClientLable}s
            around the area
          </Label>
        </div>
      )}
    </div>
  );
};

export default HospitalsMapOnboarding;
