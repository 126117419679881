import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";

import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { filter, forEach } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { ClientLable, HOSTMexxar, REGISTER_CANDIDATE } from "../../../configs/api-config";

function TimeSheetUpload(props) {
  const isDataEdited = true;
  const MexxarAPI = HOSTMexxar;
  const MexxarCandidate = REGISTER_CANDIDATE;

  const { register, handleSubmit } = useForm();
  const { data, closeModal, getCandidates, auth } = props;
  const { addToast } = useToasts();

  const [likeClients, setLikeClients] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [incomingData, setIncomingData] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [candidateClients, setCandidateClients] = useState();
  const [selectedDocument, setSelectedDocument] = useState();
  const [candidateLikeWards, setCandidateLikeWards] = useState();
  const [candidateSpecialitiesCheck, setCandidateSpecialitiesCheck] = useState();
  const [candidateIdCheck, setCandidateIdCheck] = useState();
  const [modalPreviewPicture, setModalPreviewPicture] = useState();
  const [candidateDocumentUrl, setCandidateDocumentUrl] = useState();
  const [uploadedImageDocument, setUploadedImageDocument] = useState();
  const [bands, setBands] = useState();
  const [locumId, setLocumId] = useState(''); 
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onSubmit = (data) => {
    let timesheetReviewStatus = 0;

    if (checkPermissions("PAYROLE").length > 0 && selectedDocument != null) {
      timesheetReviewStatus = 1;
    } else {
      timesheetReviewStatus = 0;
    }

    let timesheet = {
      locumName: data.locumName,
      locumId: data.locumId,
      speciality: data.speciality,
      band: data.band,
      hospitalName: data.candidateClientSelect,
      dateOfShift: data.dateOfShift,
      ward: data.candidateLikeWardsSelect,
      bookingRef: data.RefNo,
      startTime: data.StartTime,
      endTime: data.endTime,
      minsTakenForBreaks: data.breakTime,
      totalHours: data.totalHours,
      locumDate: data.locumDate,
      // authoriseName: data.authoriseName,
      // authorisePosition: data.authorisePosition,
      // authoriseDate: data.authoriseDate,
      bookingId: incomingData.id,
      paymentStatus: 0,
      reviewStatus: timesheetReviewStatus,
      hospitalReviewStatus: 0,
      // earnings: 100,
    };

    if (selectedDocument != null || selectedDocument != undefined) {
      axios.post(MexxarAPI + "timesheets/", timesheet).then((res) => {
        if (res.data.status == "success" || res.data.message == "created") {
          ToastCommon(true, "update", addToast);

          if (selectedDocument != null || selectedDocument != undefined) {
            axios
              .put(
                MexxarAPI +
                  "timesheets/" +
                  res.data.body[0].id +
                  "/upload-timesheetDocument",
                selectedDocument
              )
              .then((res) => {
                if (
                  res.data.status == "success" ||
                  res.data.message == "created"
                ) {
                  ToastCommon(true, "update", addToast);
                  getCandidates();
                  closeModal();
                } else {
                  ToastCommon(true, "duplicate", addToast);
                }
              })
              .catch((err) => {
                ToastCommon(true, "error", addToast);
              });
          } else {
            getCandidates();
            closeModal();
          }
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      });
    } else {
      ToastCommon(true, "documentrequired", addToast);
    }
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      let form = new FormData();
      form.append("file", e.target.files[0]);
      setSelectedDocument(form);
    }
  };

  const checkPermissions = (permission) => {
    let array = filter(auth.item.userRole.permissions, (item) => {
      return item.name == permission;
    });
    return array;
  };

  const GetSpecialties = () => {
    axios.get(MexxarCandidate + "/" + data.candidatesId).then((res) => {
      let preferedClient = [];
      forEach(res.data.body[0].preferredClients, function (client) {
        preferedClient.push({
          id: client.id,
          name: client.name,
        });
      });

      setLikeClients(preferedClient);
      
    });
  };

  const getBands = () => {
    axios.get(MexxarAPI + "candidate-bands").then((bands) => {
      setBands(bands.data.body);
    });
  };

  
  const getCandidateUser = (candidateId) => {
    axios.get(MexxarAPI + "candidates/" + candidateId).then((candidate) => {
      let hospitals = candidate.data.body[0].preferredClients;
      if (hospitals !== undefined) {
        let candidateHospitals = [];
        for (let i = 0; i < hospitals.length; i++) {
          candidateHospitals.push({
            value: hospitals[i].name,
            label: hospitals[i].name,
            id: hospitals[i].id,
            candidate: candidate,
          });
        }
        setCandidateClients(candidateHospitals);
      }

      let likeWards = candidate.data.body[0].likeWards;
      if (likeWards !== undefined) {
        let candidateLikeWards = [];
        for (let i = 0; i < likeWards.length; i++) {
          if (likeWards[i] !== undefined) {
          
            candidateLikeWards.push({
              value: likeWards[i].wardName,
              label: likeWards[i].wardName,
              id: likeWards[i].id,
              candidate: candidate,
            });
          }
        }
        setCandidateLikeWards(candidateLikeWards);
        
      }

      let candidateSpecialities = candidate.data.body[0].candidateSpecialities;
      if (candidateSpecialities !== undefined) {
       
        let candidateSpecialitiesCheck = [];
        for (let i = 0; i < candidateSpecialities.length; i++) {
          candidateSpecialitiesCheck.push({
            value: candidateSpecialities[i].name,
            label: candidateSpecialities[i].type,
            id: candidateSpecialities[i].id,
            candidate: candidate,
          });
        }
        setCandidateSpecialitiesCheck(candidateSpecialitiesCheck);
     
      }

      let id = candidate.data.body[0].id;
      if (id !== undefined) {
     
        setLocumId(id); 
        let candidateIdCheck = [];
        for (let i = 0; i < id.length; i++) {
          candidateIdCheck.push({
            value: id,
            label: id,
            id: id,
            candidate: candidate,
          });
        }
        setCandidateIdCheck(candidateIdCheck);
      
      }

      setCandidateDocumentUrl(candidate.data.body[0].documentUrl);
    });
  };


  const loadModalPreviewPicture = () => {
    if (incomingData != undefined) {
      let url = String(
        MexxarAPI +
          "timesheet-doc/candidates/" +
          incomingData.candidatesId +
          "/" +
          uploadedImageDocument
      );
      const config = { responseType: "blob" };
      axios.get(url, config).then((res) => {
        getBase64(new File([res.data], "sample"), (result) => {
          setModalPreviewPicture(result);
          setOpenModal(true);
        });
      });
    }
  };

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
    
    };
  }

  useEffect(() => {
    setIncomingData(data);
    GetSpecialties();
    getCandidateUser(data.candidatesId);
    getBands();
    loadModalPreviewPicture();
  }, []);


 
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {}
        <FormGroup row>
          {}
          <Label sm={2} md={2}>
            Ref
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="text"
              placeholder="Ref No"
              name="RefNo"
              id="RefNoId"
              defaultValue={
                incomingData == undefined || incomingData == null
                  ? ""
                  : String(incomingData.referenceNo)
              }
              innerRef={register({ required: true })}
            />
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          {}
          <Label sm={2} md={2}>
            Locum Name
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="text"
              placeholder="Locum Name"
              name="locumName"
              id="locumNameId"
              innerRef={register({ required: true })}
              defaultValue={
                incomingData == undefined || incomingData == null
                  ? ""
                  : String(incomingData.candidateName
                  )
              }
            
            />
           
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          {}
          <Label sm={2} md={2}>
            Locum ID
          </Label>
          <Col sm={9} md={9}>
          <Input
        type="text"
        placeholder="Locum Id"
        name="locumId"
        id="locumId"
        value={locumId} 
        innerRef={register({ required: true })}
      />
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} md={2}>
            Speciality
          </Label>
          <Col sm={9} md={9}>
           
          <Input
              type="select"
              name="candidateSpecialitiesCheck"
              innerRef={register({ required: true })}
            >
              {candidateSpecialitiesCheck === undefined || null ? (
                ""
              ) : (
                <>
                  {candidateSpecialitiesCheck.map((item, id) => {
                    return (
                      <option value={item.id} key={id}>
                      {item.value}
                    </option>
                    );
                  })}

                
                </>
              )}
            </Input>

         
            

          </Col>

          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} md={2}>
            Band
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="select"
              name="band"
              innerRef={register({ required: true })}
            >
              {bands === undefined || bands === null ? (
                ""
              ) : (
                <>
                  {bands.map((band, id) => {
                    return (
                      <option value={band.id} key={band.id}>
                        {band.name}
                      </option>
                    );
                  })}
                </>
              )}
            </Input>
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} md={2}>
            {ClientLable}
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="select"
              name="candidateClientSelect"
              innerRef={register({ required: true })}
            >
              {candidateClients === undefined || null ? (
                ""
              ) : (
                <>
                  {candidateClients.map((item, id) => {
                    return (
                      <option value={item.value} key={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </>
              )}
            </Input>
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} md={2}>
            Department
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="select"
              name="candidateLikeWardsSelect"
              innerRef={register({ required: true })}
            >
              {candidateLikeWards === undefined || null ? (
                ""
              ) : (
                <>
                  {candidateLikeWards.map((item, id) => {
                    return (
                      <option value={item.id} key={id}>
                        {item.value}
                      </option>
                    );
                  })}

                
                </>
              )}
            </Input>
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} md={2}>
            Date Of Shift
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="date"
              name="dateOfShift"
              innerRef={register({ required: true })}
              defaultValue={
                incomingData == undefined || incomingData == null
                  ? ""
                  : moment(incomingData.bookingDate).format("YYYY-MM-DD")
              
              }
            ></Input>
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

      
        <FormGroup row>
          <Label sm={2} md={2}>
            From
          </Label>
          <Col sm={3}>
            <Input
              type="time"
              placeholder="Start Time"
              name="StartTime"
              innerRef={register({ required: true })}
              defaultValue={
                incomingData == undefined || incomingData == null
                  ? ""
                  : moment(incomingData.shiftFrom).format("hh:mm")
              }
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
          <Label sm={2} md={2}>
            To
          </Label>
          <Col sm={3}>
            <Input
              type="time"
              placeholder="Start Time"
              name="endTime"
              defaultValue={
                incomingData == undefined || incomingData == null
                  ? ""
                  : moment(incomingData.shiftTo).format("hh:mm")
              }
              innerRef={register({ required: true })}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2}>Break</Label>
          <Col sm={9}>
            <Input
              type="time"
              placeholder="Break Time"
              name="breakTime"
              innerRef={register()}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2}>Total Hours</Label>
          <Col sm={9}>
            <Input
              type="text"
              placeholder="Total Hours"
              name="totalHours"
              innerRef={register()}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2}>Locum Date</Label>
          <Col sm={9}>
            <Input
              type="date"
              placeholder="Locum Date"
              name="locumDate"
              innerRef={register()}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        {/* <FormGroup row>
          <Label sm={2}>Authorise Name</Label>
          <Col sm={9}>
            <Input
              type="text"
              placeholder="Authorise Name"
              name="authoriseName"
              innerRef={register()}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup> */}
{/* 
        <FormGroup row>
          <Label sm={2}>Authorise Position</Label>
          <Col sm={9}>
            <Input
              type="text"
              placeholder="Authorise Position"
              name="authorisePosition"
              innerRef={register()}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup> */}

        {/* <FormGroup row>
          <Label sm={2}>Authorise Date</Label>
          <Col sm={9}>
            <Input
              type="date"
              placeholder="Authorise Date"
              name="authoriseDate"
              innerRef={register()}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup> */}

        {/* <FormGroup row>
     <Label sm={3}>Time Sheet</Label>
     <Col sm={8}>
     <h5><Badge href="#" color="primary">Manual</Badge>{'  '}</h5> */}
        {/* <Badge href="#" color="primary">Review</Badge></h5> */}
        {/* </Col>
        </FormGroup> */}
        <FormGroup row>
          <Label sm={4}>Image</Label>
          <Col sm={8}>
            {/* <img src="https://mdbootstrap.com/img/Others/documentation/img%20(75)-mini.jpg" alt="thumbnail" className="img-thumbnail" /> */}

            {/* <Label sm={4}>{uploadedImageDocument}</Label> */}
            {uploadedImageDocument == null ||
            uploadedImageDocument == undefined ? (
              <Input
                type="file"
                id="fileId"
                onChange={onChangePicture}
                name="file"
              />
            ) : (
              <>
                <Button onClick={loadModalPreviewPicture}>
                  View Uploaded File
                </Button>
                <Label sm={12} for="file">
                  Or Reupload
                </Label>
                <Input
                  type="file"
                  id="fileId"
                  onChange={onChangePicture}
                  name="file"
                />
              </>
            )}
          </Col>
          <Col sm={8}>{/* <img src={modalPreviewPicture} sm={4}/> */}</Col>
        </FormGroup>
        <div className="  d-flex justify-content-end">
          <Button color="success" type="submit" className="mx-2" onClick={() => closeModal()}>
            Upload
          </Button>

          <Button
            color="secondary"
            className="mr-3"
            onClick={() => closeModal()}
          >
            Close
          </Button>
        </div>
      </Form>
      <Modal
        isOpen={openModal}
        scrollable={false}
        className="modal-dialog modal-lg"
      >
        <Form>
          {"Text" && (
            <ModalHeader className="modal-header bg-primary">
              <span style={{ color: "#ffffff" }}>{"Document Preview"}</span>
            </ModalHeader>
          )}
          <ModalBody className="p-4 ">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <label for="file-input">
                  <img
                    src={modalPreviewPicture}
                    width={"700px"}
                    height={"700px"}
                  ></img>
                </label>
              </div>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(TimeSheetUpload);
