import React, { useEffect } from "react";
import { useState } from "react";
import DOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { isEmpty } from "lodash";
import setAuthorizationToken from "../../util/auth/SetAuthorizationToken";
import axios from "axios";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { Label } from "reactstrap";

export default function ThreadDetails({ threads, selectedAccount }) {
  const [isOpen, setIsOpen] = useState(new Array(threads.length).fill(false));
  const { instance, accounts } = useMsal();

  const [emailBody, setEmailBody] = useState(""); // State to hold the email body

  function RequestEmailGetAddAttachment(item) {
    console.log("Attachments fetched: 011"); // Log fetched attachments

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: isEmpty(selectedAccount)
          ? accounts[0]
          : selectedAccount?.account,
      })
      .then((response) => {
        setAuthorizationToken(response?.accessToken, "email");
        document.getElementById("attached-files").innerHTML =
          "Downloading Attachments...";
        axios
          .get(
            `https://graph.microsoft.com/v1.0/me/messages/${item.id}/attachments`
          )
          .then((response) => {
            const attachments = response?.data?.value;
            const uniqueAttachmentNames = new Set();
            const cidToAttachmentMap = {};

            // Map attachments to their Content IDs
            const attachmentLinks = attachments
              .filter((attachment) => {
                if (!uniqueAttachmentNames.has(attachment.name)) {
                  uniqueAttachmentNames.add(attachment.name);
                  if (attachment.contentId) {
                    const imageName = attachment.name; // Extract CID without domain
                    cidToAttachmentMap[imageName] = attachment;
                    console.log(
                      `Mapped CID ${imageName} to attachment:`,
                      attachment
                    ); // Log CID mapping
                  }
                  return true;
                }
                return false;
              })
              .map((attachment) => {
                const base64String = attachment.contentBytes;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                  type: attachment.contentType,
                });
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
              });

            const attachedFiles = attachmentLinks.join(", ");
            document.getElementById("attached-files").innerHTML = attachedFiles;

            const bodyContent = item?.body?.content; // Get the original email body
            console.log("Original email body:", bodyContent); // Log the original body

            let updatedBodyContent = bodyContent;
            console.log("cidToAttachmentMap:", cidToAttachmentMap); // Log fetched attachments

            updatedBodyContent = updatedBodyContent.replace(
              /<img[^>]+src="cid:([^"]+)"[^>]*>/g,
              (match, cid) => {
                const cidWithoutDomain = cid.split("@")[0]; // Strip off any domain part of the CID
                const attachment = cidToAttachmentMap[cidWithoutDomain]; // Look up the CID without the domain
                console.log(`Processing CID: ${cid}`); // Log current CID being processed
                if (attachment) {
                  console.log("Found matching attachment:", attachment); // Log matched attachment

                  const base64String = attachment.contentBytes;
                  const byteCharacters = atob(base64String);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], {
                    type: attachment.contentType,
                  });
                  const newUrl = URL.createObjectURL(blob);
                  console.log("Replaced CID with new image URL:", newUrl); // Log new image URL
                  return `<img src="${newUrl}" alt="${attachment.name}" style="max-width: 100%; height: auto;" />`;
                }
                console.log("No matching attachment found for CID:", cid); // Log if no match found
                return match; // Return the original image tag if no match found
              }
            );

            console.log(
              "Updated email body after replacement:",
              updatedBodyContent
            ); // Log updated body content

            // Update the email body state
            setEmailBody(updatedBodyContent); // Save the new body in state
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  function toggleCard(index) {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
    console.log("angi test threads---->", threads[index]);
    RequestEmailGetAddAttachment(threads[index]);
  }

  const sanitizedData = (thread) => ({
    __html: DOMPurify.sanitize(thread.body.content),
  });

  function getRelevantDate(email, userEmailAddress) {
    // Check if the user is the sender or the recipient
    const isSender = email?.from?.emailAddress?.address === userEmailAddress;
    const isRecipient = email?.toRecipients?.some(
      (recipient) => recipient.emailAddress.address === userEmailAddress
    );

    if (isSender) {
      // User sent the email, so show the sent date
      return email?.sentDateTime;
    } else if (isRecipient) {
      // User received the email, so show the received date
      return email?.receivedDateTime;
    } else {
      // Fallback if neither
      return email?.createdDateTime || email?.lastModifiedDateTime;
    }
  }

  return (
    <div>
      {threads.map((thread, index) => (
        <div
          key={index}
          className=" px-3 m-2"
          style={{
            border: "1px solid #cccccc",
          }}
        >
          <div
            className="card-header pointer d-flex justify-content-between mb-2 "
            style={{
              borderBottom: "1px solid #cccccc",
            }}
            onClick={() => toggleCard(index)}
          >
            <span className="font-weight-bold">
              {threads?.[index]?.sender?.emailAddress?.name}:
            </span>
            {/* Show the relevant date based on sender/recipient */}
            <div className="mr-2 d-flex">
              <div>
                {new Date(
                  getRelevantDate(
                    threads[index],
                    isEmpty(selectedAccount)
                      ? accounts?.[0]?.email
                      : selectedAccount?.account?.email
                  )
                ).toLocaleString()}{" "}
              </div>
              <div>
                {isOpen[index] ? (
                  <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
                ) : (
                  <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                )}
              </div>
            </div>
          </div>

          {isOpen[index] && thread && thread?.body?.contentType == "html" && (
            <>
              <span>
                {threads[index].hasAttachments ? (
                  <Label className="mr-2">
                    <FontAwesomeIcon
                      icon={faPaperclip}
                      className="mr-2"
                    ></FontAwesomeIcon>
                    Attachment(s):
                  </Label>
                ) : (
                  <></>
                )}
                <span id="attached-files"></span>
              </span>
              {threads?.[index]?.body?.contentType === "html" && (
                <div
                  dangerouslySetInnerHTML={{ __html: emailBody }} // Render email body with updated content
                />
              )}
            </>
          )}
          {isOpen[index] && thread && thread?.body?.contentType == "text" && (
            <>
              <span>
                {threads[index].hasAttachments ? (
                  <Label className="mr-2">
                    <FontAwesomeIcon
                      icon={faPaperclip}
                      className="mr-2"
                    ></FontAwesomeIcon>
                    Attachment(s):
                  </Label>
                ) : (
                  <></>
                )}
                <span id="attached-files" className="text-info"></span>
              </span>
              <pre
                style={{
                  backgroundColor: "inherit",
                  color: "inherit",
                  whiteSpace: "break-spaces",
                }}
              >
                {threads?.[index]?.body?.content}
              </pre>
            </>
          )}
        </div>
      ))}

      <style js>{`

    
.card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-content > div {
   padding-bottom: 1rem;
}

.card-content > div:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.card-content span:first-child {
  font-weight: bold;
}

.card-header .material-icons {
  display: none;
}


`}</style>
    </div>
  );
}
