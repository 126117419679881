import React, { useState, useEffect } from "react";

// import logo from "../../images/ramsyLogo.png";
import logo from "../../images/candiHealthCare.jpg";
// import logo from "../../images/emergencyPersonnelSmall.png";
// import logo from "../../images/mexxarLogo.png";

import { connect } from "react-redux";
import { Collapse, Badge } from "reactstrap";
import { signOutAction } from "../../redux/actions/authAction";
import { Link, useLocation } from "react-router-dom";
import IsPrivileged from "../../util/auth/privilegeHelper.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBillAlt,
  faChartArea,
  faDatabase,
  faUserNurse,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import store from "../../redux/store";
import { changeSideNavigation } from "../../redux/actions/appSettingsAction";
import {
  CalendarIcon,
  ChatBubbleIcon,
  ClockIcon,
  CreditCardIcon,
  CrossArrowIcon,
  DocumentIcon,
  DocumentStackIcon,
  EnvelopeIcon,
  HomeIcon,
  MedalIcon,
  OnboardingIcon,
  PeopleIcon,
  PeoplePlusIcon,
  PoundSignIcon,
  PowerIcon,
  RoundCheckIcon,
  StackIcon,
  TrendingIcon,
  WavesIcon,
} from "../../assets/icons/svg";
import {
  ClientLable,
  CompanyName,
  Logo,
  Version,
} from "../../configs/api-config";

const SideNavigation = (props) => {
  const { settings } = props;
  const location = useLocation();
  const [candidateIsOpen, setCandidateIsOpen] = useState(false);
  const [candyIsOpen, setCandyIsOpen] = useState(false);
  const [adminIsOpen, setAdminIsOpen] = useState(false);
  const [superAdminIsOpen, setSuperAdminIsOpen] = useState(false);
  const [oldActiveItem, setOldActiveItem] = useState(false);
  const [activeItem, setActiveItem] = useState(location.pathname);
  const [dontCollapse, setDontCollapse] = useState(false);

  const { authenticated } = props;

  const toggleCandidateMenu = () => {
    if (!candidateIsOpen) {
      setOldActiveItem(activeItem);
      setActiveItem("candidate");
    } else {
      setActiveItem(oldActiveItem);
    }

    setCandidateIsOpen(!candidateIsOpen);
  };

  const toggleCandyeMenu = () => {
    if (!candyIsOpen) {
      setOldActiveItem(activeItem);
      setActiveItem("candy");
    } else {
      setActiveItem(oldActiveItem);
    }

    setCandyIsOpen(!candyIsOpen);
  };

  const toggleAdminMenu = () => {
    if (!adminIsOpen) {
      setOldActiveItem(activeItem);
      setActiveItem("admin");
    } else {
      setActiveItem(oldActiveItem);
    }
    setAdminIsOpen(!adminIsOpen);
  };

  const toggleSuperAdminMenu = () => {
    if (!superAdminIsOpen) {
      setOldActiveItem(activeItem);
      setActiveItem("superAdmin");
    } else {
      setActiveItem(oldActiveItem);
    }
    setSuperAdminIsOpen(!superAdminIsOpen);
  };

  const navigate = (navigateItem) => {
    setActiveItem(navigateItem);
  };

  useEffect(() => {
    if (settings.oldwindowSize && settings.oldwindowSize < 992) {
      store.dispatch(changeSideNavigation(false));
    } else {
      store.dispatch(changeSideNavigation(true));
    }
  }, [settings.oldwindowSize]);

  return (
    <>
      <div
        id="aside"
        className={`page-sidenav no-shrink bg-light nav-dropdown fade sticky  ${
          settings.isSideNavigationOpen ? "show d-block" : "hide d-none"
        }`}
        // onClick={() => store.dispatch(changeSideNavigation(false))}
      >
        <div className="sidenav h-100 modal-dialog bg-light">
          <div className="flex scrollable hover">
            <div className="pt-2">
              <div className="nav-fold px-2">
                <a className="d-flex p-2" data-toggle="dropdown">
                  <span className="avatar w-40 rounded hide"></span>
                  <img src={logo} alt="..." className="w-40 r"></img>
                </a>
                <div className="hidden-folded flex p-2">
                  <div className="d-flex">
                    <a href="#" className="mr-auto text-nowrap">
                      {authenticated.isAuthenticated
                        ? `${authenticated.item.firstName} ${authenticated.item.lastName}`
                        : "UserName Unknown"}
                      <small className="d-block text-muted">
                        {authenticated.item.userRole
                          ? authenticated.item.userRole.name
                          : "Role Unknown"}
                      </small>
                      <Badge color="warning">{Version} </Badge>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="nav-active-primary auto-nav" data-nav="">
              <ul className="nav">
                <IsPrivileged
                  privilege={["EMPLOYEE_PERM", "ADMIN", "EMPLOYEE_TEMP"]}
                >
                  <li
                    onClick={() => setActiveItem("/dashboard")}
                    className={activeItem === "/dashboard" ? "active" : ""}
                  >
                    <Link to="/dashboard">
                      <span className="nav-icon">
                        <HomeIcon />
                      </span>
                      <span className="nav-text">Dashboard</span>
                    </Link>
                  </li>
                </IsPrivileged>

                <IsPrivileged privilege={["CANDIDATE"]}>
                  <li
                    onClick={() =>
                      setActiveItem(
                        "/dashboard/candidate-profile/" +
                          authenticated.item.userTypeId +
                          "/" +
                          authenticated.item.userTypeId
                      )
                    }
                    className={
                      activeItem ===
                      "/dashboard/candidate-profile/" +
                        authenticated.item.userTypeId +
                        "/" +
                        authenticated.item.userTypeId
                        ? "active"
                        : ""
                    }
                  >
                    <Link
                      to={{
                        pathname:
                          "/dashboard/candidate-profile/" +
                          authenticated.item.userTypeId +
                          "/" +
                          authenticated.item.userTypeId,
                      }}
                    >
                      <span className="nav-icon text-info">
                        <CalendarIcon />
                      </span>
                      <span className="nav-text">Profile</span>
                    </Link>
                  </li>
                </IsPrivileged>

                <IsPrivileged
                  privilege={["EMPLOYEE_PERM", "ADMIN", "EMPLOYEE_TEMP"]}
                >
                  <li
                    // className={
                    //   activeItem.includes("candidates") ? "active" : ""
                    // }
                    className={
                      activeItem.includes("candidates") || candidateIsOpen
                        ? "active"
                        : ""
                    }
                  >
                    <a onClick={() => toggleCandidateMenu()}>
                      <span className="nav-icon text-success">
                        <PeopleIcon />
                      </span>
                      <span className="nav-text">Candidates</span>
                      <span className="nav-caret"></span>
                    </a>
                    <Collapse isOpen={candidateIsOpen}>
                      <ul className="nav">
                        <li
                          onClick={() => setActiveItem("/dashboard/candidates")}
                          className={
                            activeItem === "/dashboard/candidates"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to="/dashboard/candidates">
                            <span className="nav-text">Candidates</span>
                          </Link>
                        </li>

                        <IsPrivileged privilege={["CREATE_NEW_EMPLOYEE"]}>
                          <li
                            onClick={() =>
                              setActiveItem("/dashboard/candidates/register")
                            }
                            className={
                              activeItem === "/dashboard/candidates/register"
                                ? "active"
                                : ""
                            }
                          >
                            <Link to="/dashboard/candidates/register">
                              <span className="nav-text">
                                Register New Candidate
                              </span>
                            </Link>
                          </li>
                        </IsPrivileged>
                      </ul>
                    </Collapse>
                  </li>
                </IsPrivileged>

                {/* <li
                  onClick={() => setActiveItem("/dashboard/chat")}
                  className={activeItem === "/dashboard/chat" ? "active" : ""}
                >
                  <Link to="/dashboard/chat">
                    <span className="nav-icon text-warning">
                      <ChatBubbleIcon />
                    </span>

                    <span className="nav-text">Messages</span>
                  </Link>
                </li> */}
                {/* <li
                  onClick={() => setActiveItem("/dashboard/email/loginPage")}
                  className={
                    activeItem === "/dashboard/email/loginPage" ? "active" : ""
                  }
                >
                  <Link
                    to={
                      localStorage.email
                        ? "/dashboard/email/inbox"
                        : "/dashboard/email/loginPage"
                    }
                  >
                    <span className="nav-icon text-danger">
                     <EnvelopeIcon/>
                    </span>

                    <span className="nav-text">Email</span>
                  </Link>
                </li> */}

                <li
                  onClick={() => setActiveItem("/dashboard/outlook/loginPage")}
                  className={
                    activeItem === "/dashboard/outlook/loginPage"
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={
                      localStorage.email
                        ? "/dashboard/outlook"
                        : "/dashboard/outlook/loginPage"
                    }
                  >
                    <span className="nav-icon text-danger">
                      <EnvelopeIcon />
                    </span>

                    <span className="nav-text">
                      Outlook{" "}
                      <Badge color="warning" className="ml-1">
                        BETA
                      </Badge>
                    </span>
                  </Link>
                </li>

                <IsPrivileged
                  privilege={["EMPLOYEE_PERM", "ADMIN", "EMPLOYEE_TEMP"]}
                >
                  <li
                    onClick={() => setActiveItem("/dashboard/todo")}
                    className={activeItem === "/dashboard/todo" ? "active" : ""}
                  >
                    <Link to="/dashboard/todo">
                      <span className="nav-icon text-warning">
                        <RoundCheckIcon />
                      </span>

                      <span className="nav-text">To Do List</span>
                      {/* <span className="nav-badge">
                      <b className="badge badge-pill danger">6</b>
                    </span> */}
                    </Link>
                  </li>
                </IsPrivileged>

                <IsPrivileged
                  privilege={["EMPLOYEE_PERM", "ADMIN", "EMPLOYEE_TEMP"]}
                >
                  <li
                    onClick={() => setActiveItem("/dashboard/calendar")}
                    className={
                      activeItem === "/dashboard/calendar" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/calendar">
                      <span className="nav-icon text-info">
                        <CalendarIcon />
                      </span>
                      <span className="nav-text">Calendar</span>
                    </Link>
                  </li>
                </IsPrivileged>
                <IsPrivileged
                  privilege={["EMPLOYEE_PERM", "ADMIN", "EMPLOYEE_TEMP"]}
                >
                  <li
                    onClick={() => setActiveItem("/dashboard/clients")}
                    className={
                      activeItem === "/dashboard/clients" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/clients">
                      <span className="nav-icon text-success">
                        <HomeIcon />
                      </span>
                      <span className="nav-text">{ClientLable}s</span>
                    </Link>
                  </li>
                </IsPrivileged>

                {/* <IsPrivileged privilege={["HEADHUNTER", "EMPLOYEE_PERM"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/headhunterPerm")}
                    className={
                      activeItem === "/dashboard/headhunterPerm" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/headhunterPerm">
                      <span className="nav-icon text-warning">
                        <PeoplePlusIcon />
                      </span>
                      <span className="nav-text">Headhunting Perm</span>
                    </Link>
                  </li>
                </IsPrivileged> */}

                <IsPrivileged privilege={["HEADHUNTER", "EMPLOYEE_TEMP"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/onboarding")}
                    className={
                      activeItem === "/dashboard/onboarding" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/onboarding">
                      <span className="nav-icon text-warning">
                        <OnboardingIcon />
                      </span>
                      <span className="nav-text">
                        Onboarding{" "}
                        <Badge color="warning" className="ml-1">
                          BETA
                        </Badge>
                      </span>
                    </Link>
                  </li>
                </IsPrivileged>

                <IsPrivileged privilege={["HEADHUNTER", "EMPLOYEE_TEMP"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/headhunter")}
                    className={
                      activeItem === "/dashboard/headhunter" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/headhunter">
                      <span className="nav-icon text-warning">
                        <PeoplePlusIcon />
                      </span>
                      <span className="nav-text">Headhunting Temp</span>
                    </Link>
                  </li>
                </IsPrivileged>

                <IsPrivileged privilege={["REGISTRATION", "EMPLOYEE_TEMP"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/registration")}
                    className={
                      activeItem === "/dashboard/registration" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/registration">
                      <span className="nav-icon text-warning">
                        <CrossArrowIcon />
                      </span>
                      <span className="nav-text">Registration</span>
                    </Link>
                  </li>
                </IsPrivileged>
                <IsPrivileged privilege={["AUDIT", "EMPLOYEE_TEMP"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/audit")}
                    className={
                      activeItem === "/dashboard/audit" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/audit">
                      <span className="nav-icon text-warning">
                        <DocumentIcon />
                      </span>
                      <span className="nav-text">Audit</span>
                    </Link>
                  </li>
                </IsPrivileged>
                <IsPrivileged privilege={["MAINTENANCE", "EMPLOYEE_TEMP"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/maintenance")}
                    className={
                      activeItem === "/dashboard/maintenance" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/maintenance">
                      <span className="nav-icon text-warning">
                        <DocumentStackIcon />
                      </span>
                      <span className="nav-text">Maintenance</span>
                    </Link>
                  </li>
                </IsPrivileged>
                <IsPrivileged privilege={["BOOKING", "EMPLOYEE_TEMP"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/booking")}
                    className={
                      activeItem === "/dashboard/booking" ? "active" : ""
                    }
                    id="BookingSideNavigation"
                  >
                    <Link to="/dashboard/booking">
                      <span className="nav-icon text-info">
                        <ClockIcon />
                      </span>
                      <span className="nav-text">Bookings</span>
                    </Link>
                  </li>
                </IsPrivileged>

                <IsPrivileged
                  privilege={["EMPLOYEE_TEMP", "EMPLOYEE_PERM", "ADMIN"]}
                >
                  <li
                    onClick={() => setActiveItem("/dashboard/employee")}
                    className={
                      activeItem === "/dashboard/employee" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/employee">
                      <span className="nav-icon text-success">
                        <PeopleIcon />
                      </span>
                      <span className="nav-text">Employees</span>
                    </Link>
                  </li>
                </IsPrivileged>
                {/* <IsPrivileged privilege={["ADMIN"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/drafts")}
                    className={
                      activeItem === "/dashboard/drafts" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/drafts">
                      <span className="nav-icon text-warning">
                       <DocumentIcon/>
                      </span>
                      <span className="nav-text">Drafts</span>
                    </Link>
                  </li>
                </IsPrivileged> */}

                <IsPrivileged
                  privilege={["EMPLOYEE_TEMP", "EMPLOYEE_PERM", "ADMIN"]}
                >
                  <li
                    onClick={() => setActiveItem("/dashboard/payroll")}
                    className={
                      activeItem === "/dashboard/payroll" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/payroll">
                      <span className="nav-icon  text-success">
                        <PoundSignIcon />
                      </span>
                      <span className="nav-text">
                        Payroll
                        <Badge color="warning" className="ml-1">
                          BETA
                        </Badge>
                      </span>
                    </Link>
                  </li>
                </IsPrivileged>

                {/* <IsPrivileged
                  privilege={["CREDIT_CONTROL","EMPLOYEE_TEMP","EMPLOYEE_PERM", "ADMIN"]}
                >
                  <li
                    onClick={() => setActiveItem("/dashboard/creditControll")}
                    className={
                      activeItem === "/dashboard/creditControll" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/creditControll">
                      <span className="nav-icon text-success">
                        <CreditCardIcon />
                      </span>
                      <span className="nav-text">
                        Credit Control 
                        <Badge color="warning" className="ml-1">
                          demo
                        </Badge>
                      </span>
                    </Link>
                  </li>
                </IsPrivileged> */}

                <IsPrivileged privilege={["ADMIN", "EMPLOYEE"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/reports")}
                    className={
                      activeItem === "/dashboard/reports" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/reports">
                      <span className="nav-icon text-warning">
                        <FontAwesomeIcon icon={faChartArea}></FontAwesomeIcon>
                      </span>
                      <span className="nav-text">Reports</span>
                    </Link>
                  </li>
                </IsPrivileged>

                <IsPrivileged privilege={["ADMIN", "EMPLOYEE"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/bulk-reports")}
                    className={
                      activeItem === "/dashboard/bulk-reports" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/bulk-reports">
                      <span className="nav-icon text-warning">
                        <FontAwesomeIcon icon={faMailBulk}></FontAwesomeIcon>
                      </span>
                      <span className="nav-text">Bulk Email Reports</span>
                    </Link>
                  </li>
                </IsPrivileged>

                {/* <IsPrivileged privilege={["ADMIN", "EMPLOYEE_PERM"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/jobBoardPerm")}
                    className={
                      activeItem === "/dashboard/jobBoardPerm" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/jobBoardPerm">
                      <span className="nav-icon text-warning">
                        <FontAwesomeIcon icon={faUserNurse}></FontAwesomeIcon>
                      </span>
                      <span className="nav-text">Job Board Perm-Beta</span>
                    </Link>
                  </li>
                </IsPrivileged> */}
                {/* <li>
                    <a href="#">
                      <span className="nav-icon text-warning">
                        <TrendingIcon /> 
                      </span>
                      <span className="nav-text">
                        <small> Business Development</small>

                        <Badge color="warning" className="ml-1">
                          demo
                        </Badge>
                      </span>
                    </a>
                  </li> */}

                <IsPrivileged privilege={["ADMIN"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/tickets")}
                    className={
                      activeItem === "/dashboard/tickets" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/tickets">
                      <span className="nav-icon">
                        <StackIcon />
                      </span>
                      <span className="nav-text">Support Request</span>
                    </Link>
                  </li>
                </IsPrivileged>
                {/* <IsPrivileged privilege={["ADMIN", "EMPLOYEE_TEMP","EMPLOYEE_PERM"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/sms")}
                    className={activeItem === "/dashboard/sms" ? "active" : ""}
                  >
                    <Link to="/dashboard/sms">
                      <span className="nav-icon text-warning">
                        <EnvelopeIcon />
                      </span>
                      <span className="nav-text">SMS</span>
                    </Link>
                  </li>
                </IsPrivileged> */}
                {/* <IsPrivileged privilege={["ADMIN", "EMPLOYEE_TEMP","EMPLOYEE_PERM"]}>
                  <li
                    onClick={() => setActiveItem("/dashboard/trainings")}
                    className={
                      activeItem === "/dashboard/trainings" ? "active" : ""
                    }
                  >
                    <Link to="/dashboard/trainings">
                      <span className="nav-icon text-success">
                        <MedalIcon />
                      </span>
                      <span className="nav-text">Trainings </span>
                    </Link>
                  </li>
                </IsPrivileged> */}
              </ul>
            </div>
          </div>
          <div className="py-2 mt-2 b-t no-shrink nav-active-primary auto-nav  ">
            <ul className="nav no-border">
              {/* Perm admin limited features for corner */}
              <IsPrivileged privilege={["ADMIN_CANDY_LIMITED_FEATURES"]}>
                <li className={activeItem.includes("candy") ? "active" : ""}>
                  <a href="#" onClick={() => toggleCandyeMenu()}>
                    {/* <span className="nav-caret"></span> */}
                    {/* </a> */}
                    {/* <a href="#" onClick={() => toggleAdminMenu()}> */}
                    <span className="nav-icon text-success">
                      <PeopleIcon />
                    </span>
                    <span
                      className="nav-text"
                      onClick={() => toggleCandyeMenu()}
                    >
                      {CompanyName} Admin
                    </span>
                    <span className="nav-caret"></span>
                    {/* </a> */}
                  </a>
                  <Collapse isOpen={candyIsOpen}>
                    <ul
                      className="nav"
                      style={{ overflowY: "auto", height: "400px" }}
                    >
                      {/* <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/appPackDocuments")
                        }
                        className={
                          activeItem === "/dashboard/admin/appPackDocuments"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/appPackDocuments">
                          <span className="nav-text">App Pack Documents</span>
                        </Link>
                      </li> */}
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/assignmentCodes")
                        }
                        className={
                          activeItem === "/dashboard/admin/assignmentCodes"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/assignmentCodes">
                          <span className="nav-text">Assignment Codes</span>
                        </Link>
                      </li>

                      <li
                        onClick={() => setActiveItem("/dashboard/admin/bands")}
                        className={
                          activeItem === "/dashboard/admin/bands"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/bands">
                          <span className="nav-text">Bands</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/candidateRoles")
                        }
                        className={
                          activeItem === "/dashboard/admin/candidateRoles"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/candidateRoles">
                          <span className="nav-text">Candidate Roles</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/cvsources")
                        }
                        className={
                          activeItem === "/dashboard/admin/cvsources"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/cvsources">
                          <span className="nav-text">CV Sources</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/emailTemplate")
                        }
                        className={
                          activeItem === "/dashboard/admin/emailTemplate"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/emailTemplate">
                          <span className="nav-text">Email Template</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/frameworks")
                        }
                        className={
                          activeItem === "/dashboard/admin/frameworks"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/frameworks">
                          <span className="nav-text">Frameworks</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/permDocuments")
                        }
                        className={
                          activeItem === "/dashboard/admin/permDocuments"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/permDocuments">
                          <span className="nav-text">Perm Documents</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/specialities")
                        }
                        className={
                          activeItem === "/dashboard/admin/specialities"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/specialities">
                          <span className="nav-text">Specialities</span>
                        </Link>
                      </li>
                      <li
                        onClick={() => setActiveItem("/dashboard/admin/teams")}
                        className={
                          activeItem === "/dashboard/admin/teams"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/teams">
                          <span className="nav-text">Teams</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/tickets")
                        }
                        className={
                          activeItem === "/dashboard/admin/tickets"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/tickets">
                          <span className="nav-text">Tickets</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/trainings")
                        }
                        className={
                          activeItem === "/dashboard/admin/trainings"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/trainings">
                          <span className="nav-text">Trainings </span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/trainingproviders")
                        }
                        className={
                          activeItem === "/dashboard/admin/trainingproviders"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/trainingproviders">
                          <span className="nav-text">Training Providers </span>
                        </Link>
                      </li>

                      <li
                        onClick={() => setActiveItem("/dashboard/admin/trusts")}
                        className={
                          activeItem === "/dashboard/admin/trusts"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/trusts">
                          <span className="nav-text">Trusts </span>
                        </Link>
                      </li>

                      <li
                        onClick={() => setActiveItem("/dashboard/admin/wards")}
                        className={
                          activeItem === "/dashboard/admin/wards"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/wards">
                          <span className="nav-text">Wards</span>
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              </IsPrivileged>

              <IsPrivileged privilege={["ADMIN"]}>
                <li className={activeItem.includes("admin") ? "active" : ""}>
                  <a href="#" onClick={() => toggleAdminMenu()}>
                    {/* <span className="nav-caret"></span> */}
                    {/* </a> */}
                    {/* <a href="#" onClick={() => toggleAdminMenu()}> */}
                    <span className="nav-icon text-success">
                      <PeopleIcon />
                    </span>
                    <span
                      className="nav-text"
                      onClick={() => toggleAdminMenu()}
                    >
                      Admin
                    </span>
                    <span className="nav-caret"></span>
                    {/* </a> */}
                  </a>
                  <Collapse isOpen={adminIsOpen}>
                    <ul
                      className="nav"
                      style={{ overflowY: "auto", height: "400px" }}
                    >
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/appPackDocuments")
                        }
                        className={
                          activeItem === "/dashboard/admin/appPackDocuments"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/appPackDocuments">
                          <span className="nav-text">App Pack Documents</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/assignmentCodes")
                        }
                        className={
                          activeItem === "/dashboard/admin/assignmentCodes"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/assignmentCodes">
                          <span className="nav-text">Assignment Codes</span>
                        </Link>
                      </li>
                      <li
                        onClick={() => setActiveItem("/dashboard/admin/bands")}
                        className={
                          activeItem === "/dashboard/admin/bands"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/bands">
                          <span className="nav-text">Bands</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/candidateRoles")
                        }
                        className={
                          activeItem === "/dashboard/admin/candidateRoles"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/candidateRoles">
                          <span className="nav-text">Candidate Roles</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/clients")
                        }
                        className={
                          activeItem === "/dashboard/admin/clients"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/clients">
                          <span className="nav-text">{ClientLable}s</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem(
                            "/dashboard/admin/frameworkRelationship"
                          )
                        }
                        className={
                          activeItem ===
                          "/dashboard/admin/frameworkRelationship"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/frameworkRelationship">
                          <span className="nav-text">
                            Configure Frameworks{" "}
                          </span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/createNewConsultant")
                        }
                        className={
                          activeItem === "/dashboard/admin/createNewConsultant"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/createNewConsultant">
                          <span className="nav-text">
                            Create New Consultant{" "}
                          </span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/cvsources")
                        }
                        className={
                          activeItem === "/dashboard/admin/cvsources"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/cvsources">
                          <span className="nav-text">CV Sources</span>
                        </Link>
                      </li>

                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/documents")
                        }
                        className={
                          activeItem === "/dashboard/admin/documents"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/documents">
                          <span className="nav-text">Documents</span>
                        </Link>
                      </li>

                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/emailConfigure")
                        }
                        className={
                          activeItem === "/dashboard/admin/emailConfigure"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/emailConfigure">
                          <span className="nav-text">Email Configuration</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/emailTemplate")
                        }
                        className={
                          activeItem === "/dashboard/admin/emailTemplate"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/emailTemplate">
                          <span className="nav-text">Email Template</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/frameworks")
                        }
                        className={
                          activeItem === "/dashboard/admin/frameworks"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/frameworks">
                          <span className="nav-text">Frameworks</span>
                        </Link>
                      </li>
                      <li
                        onClick={() => setActiveItem("/dashboard/admin/levels")}
                        className={
                          activeItem === "/dashboard/admin/levels"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/levels">
                          <span className="nav-text">Levels</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/permDocuments")
                        }
                        className={
                          activeItem === "/dashboard/admin/permDocuments"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/permDocuments">
                          <span className="nav-text">Perm Documents</span>
                        </Link>
                      </li>
                      <li
                        onClick={() => setActiveItem("/dashboard/admin/shifts")}
                        className={
                          activeItem === "/dashboard/admin/shifts"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/shifts">
                          <span className="nav-text">Shifts</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/specialities")
                        }
                        className={
                          activeItem === "/dashboard/admin/specialities"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/specialities">
                          <span className="nav-text">Specialities</span>
                        </Link>
                      </li>

                      <li
                        onClick={() => setActiveItem("/dashboard/admin/teams")}
                        className={
                          activeItem === "/dashboard/admin/teams"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/teams">
                          <span className="nav-text">Teams</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/tickets")
                        }
                        className={
                          activeItem === "/dashboard/admin/tickets"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/tickets">
                          <span className="nav-text">Tickets</span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/trainings")
                        }
                        className={
                          activeItem === "/dashboard/admin/trainings"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/trainings">
                          <span className="nav-text">Trainings </span>
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/trainingproviders")
                        }
                        className={
                          activeItem === "/dashboard/admin/trainingproviders"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/trainingproviders">
                          <span className="nav-text">Training Providers </span>
                        </Link>
                      </li>

                      <li
                        onClick={() => setActiveItem("/dashboard/admin/trusts")}
                        className={
                          activeItem === "/dashboard/admin/trusts"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/trusts">
                          <span className="nav-text">Trusts </span>
                        </Link>
                      </li>

                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/consultantRoles")
                        }
                        className={
                          activeItem === "/dashboard/admin/consultantRoles"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/consultantRoles">
                          <span className="nav-text">User Roles</span>
                        </Link>
                      </li>

                      <li
                        onClick={() => setActiveItem("/dashboard/admin/wards")}
                        className={
                          activeItem === "/dashboard/admin/wards"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/wards">
                          <span className="nav-text">Wards</span>
                        </Link>
                      </li>

                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/BankHoliday")
                        }
                        className={
                          activeItem === "/dashboard/admin/BankHoliday"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/BankHoliday">
                          <span className="nav-text">Bank Holidays</span>
                        </Link>
                      </li>

                      <li
                        onClick={() =>
                          setActiveItem("/dashboard/admin/notificationsetting")
                        }
                        className={
                          activeItem === "/dashboard/admin/notificationsetting"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/admin/notificationsetting">
                          <span className="nav-text">
                            Notification Settings{" "}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>

                {/* /dashboard/admin/roles */}

                <li
                  className={activeItem.includes("superAdmin") ? "active" : ""}
                >
                  <a href="#" onClick={() => toggleSuperAdminMenu()}>
                    <span className="nav-icon text-success">
                      <WavesIcon />
                    </span>
                    <span
                      className="nav-text"
                      onClick={() => toggleSuperAdminMenu()}
                    >
                      Super Admin
                    </span>
                    <span className="nav-caret"></span>
                  </a>
                </li>

                <Collapse isOpen={superAdminIsOpen}>
                  <ul className="nav">
                    <li
                      onClick={() =>
                        setActiveItem("/dashboard/superadmin/tickets")
                      }
                      className={
                        activeItem === "/dashboard/superadmin/tickets"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/dashboard/superadmin/tickets">
                        <span
                          className="nav-text"
                          style={{ marginLeft: "3rem" }}
                        >
                          Tickets
                        </span>
                      </Link>
                    </li>

                    <li
                      onClick={() =>
                        setActiveItem("/dashboard/superadmin/creditsystem")
                      }
                      className={
                        activeItem === "/dashboard/superadmin/creditsystem"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/dashboard/superadmin/creditsystem">
                        <span
                          className="nav-text"
                          style={{ marginLeft: "3rem" }}
                        >
                          Credit System
                        </span>
                      </Link>
                    </li>

                    {/* <li
                      onClick={() =>
                        setActiveItem("/dashboard/superadmin/transactiontype")
                      }
                      className={
                        activeItem === "/dashboard/superadmin/transactiontype"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/dashboard/superadmin/transactiontype">
                        <span
                          className="nav-text"
                          style={{ marginLeft: "3rem" }}
                        >
                          Transaction Type
                        </span>
                      </Link>
                    </li> */}
                  </ul>
                </Collapse>
              </IsPrivileged>
              <li onClick={() => props.signOutAction()}>
                <a href="#">
                  <span className="nav-icon">
                    <PowerIcon />
                  </span>
                  <span className="nav-text">Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth,
    settings: state.appSettings,
  };
}

export default connect(mapStateToProps, { signOutAction })(SideNavigation);
