import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";
import {
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import axios from "axios";
import { forEach } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientLable, HOSTMexxar, REGISTER_CANDIDATE } from "../../../configs/api-config";

export default function TimeSheet(props) {
  const isDataEdited = true;
  const MexxarAPI = HOSTMexxar;
  const MexxarCandidate = REGISTER_CANDIDATE;

  const { register, handleSubmit } = useForm();
  const { data, closeModal } = props;
  const { addToast } = useToasts();

  const [likeClients, setLikeClients] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [incomingData, setIncomingData] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [candidateClients, setCandidateClients] = useState();
  const [selectedDocument, setSelectedDocument] = useState();
  const [candidateLikeWards, setCandidateLikeWards] = useState();
  const [modalPreviewPicture, setModalPreviewPicture] = useState();
  const [candidateDocumentUrl, setCandidateDocumentUrl] = useState();
  const [uploadedImageDocument, setUploadedImageDocument] = useState();

  console.log("angi timesheet props",props)

  
  console.log("angi timesheet propsss",candidateLikeWards)
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onSubmit = (data) => {
    let timesheet = {
      id: incomingData.id,
      locumName: incomingData.locumName,
      locumId: incomingData.locumId,
      speciality: incomingData.speciality,
      band: incomingData.band,
      hospitalName:
        incomingData.hospitalName == data.candidateClientSelect
          ? incomingData.hospitalName
          : data.candidateClientSelect,
      dateOfShift: incomingData.dateOfShift,
      ward:
        incomingData.ward == data.candidateLikeWardsSelect
          ? incomingData.ward
          : data.candidateLikeWardsSelect,
      bookingRef:
        incomingData.bookingRef == data.RefNo
          ? incomingData.bookingRef
          : data.RefNo,
      startTime:
        incomingData.startTime == data.StartTime
          ? incomingData.startTime
          : data.StartTime,
      endTime:
        incomingData.endTime == data.endTime
          ? incomingData.endTime
          : data.endTime,
      minsTakenForBreaks:
        incomingData.minsTakenForBreaks == data.breakTime
          ? incomingData.minsTakenForBreaks
          : data.breakTime,
      totalHours: incomingData.totalHours,
      locumDate: incomingData.locumDate,
      authoriseName: incomingData.authoriseName,
      authorisePosition: incomingData.authorisePosition,
      authoriseDate: incomingData.authoriseDate,
      documentUrl:incomingData.documentUrl,
      bookingId: incomingData.booking.id,
      hospitalReviewStatus: incomingData.hospitalReviewStatus,
      paymentStatus: incomingData.paymentStatus,
      reviewStatus: "VERIFIED",
    };

    axios.post(MexxarAPI + "timesheets/", timesheet).then((res) => {
      console.log("timesheet",res)
      if (res.data.status == "success" || res.data.message == "created") {
        ToastCommon(true, "update", addToast);
      } else {
        ToastCommon(true, "duplicate", addToast);
      }

      if (selectedDocument != null || selectedDocument != undefined) {
        axios
          .put(
            MexxarAPI +
              "timesheets/" +
              incomingData.id +
              "/upload-timesheetDocument",
            selectedDocument
          )
          .then((res) => {
            if (res.data.status == "success" || res.data.message == "created") {
              ToastCommon(true, "update", addToast);
              closeModal();
            } else {
              ToastCommon(true, "duplicate", addToast);
            }
          })
          .catch((err) => {
            ToastCommon(true, "error", addToast);
          });
      }
    });
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      let form = new FormData();
      form.append("file", e.target.files[0]);
      setSelectedDocument(form);
    }
  };


  console.log("incomingData",incomingData)
  const GetSpecialties = () => {
    axios.get(MexxarCandidate + "/" + data.booking.candidatesId).then((res) => {
      let preferedClient = [];
      forEach(res.data.body[0].preferredClients, function (client) {
        preferedClient.push({
          id: client.id,
          name: client.name,
        });
      });

      setLikeClients(preferedClient);
    });
  };

  const getCandidateUser = (candidateId) => {
    axios.get(MexxarAPI + "candidates/" + candidateId).then((candidate) => {
      console.log("timesheet doc url", candidate.data.body[0].documentUrl);
      let hospitals = candidate.data.body[0].preferredClients;
      if (hospitals != undefined) {
        let candidateHospitals = [];
        for (let i = 0; i < hospitals.length; i++) {
          candidateHospitals.push({
            value: hospitals[i].name,
            label: hospitals[i].name,
            id: hospitals[i].id,
            candidate: candidate,
          });
        }
        setCandidateClients(candidateHospitals);
      }

      let likeWards = candidate.data.body[0].likeWards;


       

      if (likeWards != undefined) {
        let candidateLikeWards = [];
        for (let i = 0; i < hospitals.length; i++) {
          if (likeWards[i] != undefined) {
            console.log("department -->", likeWards) 
            candidateLikeWards.push({
              value: likeWards[i].wardName,
              label: likeWards[i].wardName,
              id: likeWards[i].id,
              candidate: candidate,
            });
          }
        }
        setCandidateLikeWards(candidateLikeWards);
      }

      setCandidateDocumentUrl(candidate.data.body[0].documentUrl);
      // setUploadedImageDocument((candidate.data.body[0].documentUrl).split("/")[1]);
    });
  };

  const downloadUploadedDocument = () => {
    axios({
      url:
        MexxarAPI +
        "timesheet-doc/candidates/" +
        incomingData.booking.candidatesId +
        "/" +
        uploadedImageDocument,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // link.setAttribute("target", "_blank");
      link.setAttribute("download", uploadedImageDocument);
      document.body.appendChild(link);
      link.click();
      // window.open(link);
    });
  };

  const loadModalPreviewPicture = () => {
    if (incomingData != undefined) {
      let url = String(
        MexxarAPI +
          "timesheet-doc/candidates/" +
          incomingData.booking.candidatesId +
          "/" +
          uploadedImageDocument
      );
      const config = { responseType: "blob" };
      axios.get(url, config).then((res) => {
        getBase64(new File([res.data], "sample"), (result) => {
          setModalPreviewPicture(result);
          setOpenModal(true);
          // const link = document.createElement('a');
          // link.href = result;
          // link.setAttribute("target", "_blank");
          // // link.setAttribute('download', uploadedImageDocument);
          // document.body.appendChild(link);
          // link.click();
          // window.open(result);
        });
      });
    }
  };

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      //console.log("Error: ", error);
    };
  }

  useEffect(() => {
    setIncomingData(data);
    data.documentUrl == null
      ? setUploadedImageDocument(null)
      : setUploadedImageDocument(data.documentUrl.split("/")[1]);
    GetSpecialties();
    getCandidateUser(data.booking.candidatesId);
    loadModalPreviewPicture();
  }, []);
console.log("angi canddidate client list--->",candidateClients)
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {}
        <FormGroup row>
          {}
          <Label sm={2} md={2}>
            Ref
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="text"
              placeholder="Ref No"
              name="RefNo"
              id="RefNoId"
              defaultValue={
                incomingData == undefined || incomingData == null
                  ? ""
                  : String(incomingData.bookingRef)
              }
              innerRef={register({ required: true })}
            />
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} md={2}>
            {ClientLable}
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="select"
              name="candidateClientSelect"
              innerRef={register({ required: true })}
            >
              {candidateClients === undefined || null ? (
                ""
              ) : (
                <>
                  {candidateClients.map((item, id) => {
                    return (
                      <option value={item.value} key={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </>
              )}
            </Input>
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} md={2}>
            Department
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="select"
              name="candidateLikeWardsSelect"
              innerRef={register({ required: true })}
            >
              {candidateLikeWards === undefined || null ? (
                ""
              ) : (
                <>
                  {candidateLikeWards.map((item, id) => {
                    return (
                      <option value={item.id} key={id}>
                        {item.value}
                      </option>
                    );
                  })}
                </>
              )}
            </Input>
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        {/* <FormGroup row>
        <Label sm={2} md={2}>
          Shift type
        </Label>
        <Col sm={9} md={9}>
          <Input
            type="select"
            name="shiftSelect"
            innerRef={register({ required: true })}
          >
            <option>Night</option>
            <option>type 2</option>
            <option>type 3</option>
          </Input>
        </Col>
        <Col sm={1} md={1}>
          {isDataEdited ? (
            <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
          ) : null}
        </Col>
      </FormGroup> */}

        <FormGroup row>
          <Label sm={2} md={2}>
            From
          </Label>
          <Col sm={3}>
            <Input
              type="time"
              placeholder="Start Time"
              name="StartTime"
              innerRef={register({ required: true })}
              defaultValue={
                incomingData == undefined || incomingData == null
                  ? ""
                  : moment(incomingData.booking.shiftFrom).format("hh:mm")
              }
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
          <Label sm={1} md={1}>
            To
          </Label>
          <Col sm={4}>
            <Input
              type="time"
              placeholder="Start Time"
              name="endTime"
              defaultValue={
                incomingData == undefined || incomingData == null
                  ? ""
                  : moment(incomingData.booking.shiftTo).format("hh:mm")
              }
              innerRef={register({ required: true })}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2}>Break</Label>
          <Col sm={9}>
            <Input
              type="time"
              placeholder="Break Time"
              name="breakTime"
              innerRef={register()}
              defaultValue={
                incomingData == undefined || incomingData == null
                  ? ""
                  : moment(incomingData.minsTakenForBreaks, "mm:ss").format(
                      "mm:ss"
                    )
              }
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        {/* <FormGroup row>
     <Label sm={3}>Time Sheet</Label>
     <Col sm={8}>
     <h5><Badge href="#" color="primary">Manual</Badge>{'  '}</h5> */}
        {/* <Badge href="#" color="primary">Review</Badge></h5> */}
        {/* </Col>
        </FormGroup> */}
        <FormGroup row>
          <Label sm={4}>Image</Label>
          <Col sm={8}>
            {/* <img src="https://mdbootstrap.com/img/Others/documentation/img%20(75)-mini.jpg" alt="thumbnail" className="img-thumbnail" /> */}

            {/* <Label sm={4}>{uploadedImageDocument}</Label> */}
            {uploadedImageDocument == null ||
            uploadedImageDocument == undefined ? (
              <Input
                type="file"
                id="fileId"
                onChange={onChangePicture}
                name="file"
              />
            ) : (
              <>
                <Button onClick={loadModalPreviewPicture}>
                  View Uploaded File
                </Button>

                <Label sm={12} for="file" className="pl-0">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-2"
                  ></FontAwesomeIcon>
                  Replace Timesheet
                </Label>
                <Input
                  type="file"
                  id="fileId"
                  onChange={onChangePicture}
                  name="file"
                />
              </>
            )}
          </Col>
          <Col sm={8}>{/* <img src={modalPreviewPicture} sm={4}/> */}</Col>
        </FormGroup>
        <div className="  d-flex justify-content-end">
          <Button color="success" className="mx-2" type="submit"  onClick={() => closeModal()} >
            Verify
          </Button>
          <Button
            color="secondary"
            className="mr-3"
            onClick={() => closeModal()}
          >
            Close
          </Button>
        </div>
      </Form>
      <Modal
        isOpen={openModal}
        scrollable={false}
        className="modal-dialog modal-lg"
      >
        <Form>
          {"Text" && (
            <ModalHeader className="modal-header bg-primary">
              <span style={{ color: "#ffffff" }}>{"Document Preview"}</span>
            </ModalHeader>
          )}
          <ModalBody className="p-4 ">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <label for="file-input">
                  <img
                    src={modalPreviewPicture}
                    width={"700px"}
                    height={"700px"}
                  ></img>
                </label>
              </div>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
}
