import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button, Label, DropdownItem, DropdownToggle, ButtonGroup, Input } from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faCaretSquareRight,
  faCaretSquareLeft,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
  faSearchLocation,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import {
  RESCHEDULE_MAINTENANCE_WORKSPACE,
  RESCHEDULE,
  HOSTMexxar,
  HostMexxarURL,
  CALL_LOGS,
  REGISTER_CANDIDATE,
} from "../../configs/api-config";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import Select from 'react-select'

import {
  ApplicationStatus,
  GenderSwitch,
  ConversationResponse,
} from "../../util/common";
import { UncontrolledTooltip } from "reactstrap/lib";
import OnboardingCall from "../../components/Modal/ModalBody/onboardingCall";
import AddUser from "../../components/Modal/ModalBody/Admin/addUser";
import OnboardingCallDelete from "../../components/Modal/ModalBody/onboardingCallDelete";
import AvatarMexxar from "../../util/common/avatarMexxar";
import NoSearchResults from "../../pages/errorPages/NoSearchResults";
import NoConnection from "../../pages/errorPages/NoConnection";
import loadingAnimation from "../../../src/images/puff.svg";
import AddNoteModal from "../../components/AddANote/AddANote";
import AddMap from "../../components/Modal/ModalBody/Admin/addMap";




const options = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'CALLED', label: 'Call' },
  { value: 'REGISTERED', label: 'Register' },
  { value: 'DECLINE', label: 'Decline' },
];


const OnboardingUnverifiedWidget = (props) => {
  const { userProfile, tab  ,  resetFilters} = props;
  //const MexxarApi = RESCHEDULE_MAINTENANCE_WORKSPACE;
  const MexxarApiReschedule = RESCHEDULE;
  const MexxarUrl = HostMexxarURL;

  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const [candidateId, setCandidateId] = useState(false);
  const [showLastContacted, setShowLastContacted] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [phoneNumberType, setPhoneNumberType] = useState("Primary");
  const { addToast } = useToasts();

  const [calendarShow, setCalendarShow] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [candidatePhoneNo, setCandidatePhoneNo] = useState(null);
  const [status, setOnboardingStatus] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showCall, setShowCall] = useState(false);
  const [showCallDelete, setShowCallDelete] = useState(false);
  const [showRowID1, setRowID1] = useState(false);
  const [statusActive, setStatues] = useState(false);
  const handleShowLastContacted = () =>
    setShowLastContacted(!showLastContacted);
  const toggleShowEmailModal = (userProfile) => {
    setSelectedCandidate(userProfile);
    setShowEmailModal(!showEmailModal);
  };
  const [showAddRow, setShowAddRow] = useState(false);
  // const [candidatePhoneNo, setCandidatePhoneNo] = useState(null);
  const [lanndLine, setlandLine] = useState(null);
  const [secondary, setsecondaryMobile] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showAddMap, setShowMap] = useState(false);
  const [postalCode, setpostalCode] = useState(null);
  const [searchStatus, setSearchStatus] = useState({
    status: false,
    data: null,
  });




  console.log("selected Option..................", selectedOption)

  console.log("user.......................",userProfile)
  console.log("candidate.......................",candidateId)
  const MexxarHostAPI = HOSTMexxar;
  const [profilePicture, setProfilePicture] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleM = () => setShowEmailModal(!showEmailModal);

  const toggleC = () => setShowCall(!showCall);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handlePhoneNumberType = (type) => {
    setPhoneNumberType(type);
    console.log("mobile type", type);
    // Additional logic can be added here if needed
  };



  const resheduleCall = (data) => {
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    axios
      .post(MexxarApiReschedule, {
        callerId: 1,
        receiverId: rowID,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        ToastCommon(true, "success", addToast);
        setCalendarShow(!calendarShow);
      })
      .catch((err) => {
        //console.log("erorrrrrrrrrrrrrrrr", err);
        ToastCommon(true, "error", addToast);
      });
  };

  console.log("userProfile", userProfile)



  const handleSaveNote = (note) => {
    //loging a call note outside the calling page
    let tempStatusEnum;
    let tempLable;

    if (statusActive === "ACTIVE") {
      tempStatusEnum = 0;
      tempLable = "The candidate has been Activated.";
    } else if (statusActive === "STAND_BY") {
      tempStatusEnum = 5;
      tempLable = "The candidate has been set to standby.";
    } else if (statusActive === "INACTIVE") {
      tempStatusEnum = 3;
      tempLable = "The candidate has been changed from unverified to inactive.";
    }

    candidateActiveStatus(statusActive);

    axios
      .post(CALL_LOGS, {
        candidateId: Number(candidateId),
        reason: tempLable + " Reason: " + note?.note,
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString(),
        status: 6,
        candidateStatus: tempStatusEnum, // <----keep at 3=INACTIVE / 0=ACTIVE
        incomingPhoneNo: 0,
        outgoingPhoneNo: 0,
        important: true,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        if (res.data.status == "success") {
          ToastCommon(true, "success", addToast);
          resetFilters();
          toggleModal();
        } else {
          ToastCommon(true, "error", addToast);
        }
        setStatues(null);
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  const candidateActiveStatus = (candidateStatus) => {
    //Candidate status updating ex:Doment,Do not not call,Inactive
    axios
      .put(
        REGISTER_CANDIDATE +
          "/" +
          candidateId +
          "/" +
          "status" +
          "/" +
          candidateStatus
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "update", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  return (
    <>
      {/* {rows.map((userProfile, i) => ( */}
      <div
        className=" card d-flex flex-row main bg-light p-1 "
        style={{ marginBottom: "5px" }}
      >

        <AvatarMexxar
          candidateId={userProfile?.id}
          primaryPhone={userProfile?.primaryPhone}
          avatar={userProfile?.avatar}
          gender={userProfile?.gender}
        />

        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className="d-flex ml-1 mb-1" id={`candidateID${userProfile.id}`}>
            <Label>
              {userProfile.firstName}&nbsp;{userProfile.lastName}
            </Label>
          </div>
          <UncontrolledTooltip
            className="d-flex flex-column justify-content-start align-items-center p-2"
            target={`candidateID${userProfile.id}`}
          >
            <div className="d-flex border-bottom mb-2 ">Show more</div>
            <span className="d-flex">
              ID:&nbsp; <Badge className="p-1 m-0">{userProfile.id}</Badge>
            </span>
            <div>
              <Label className="text-left">
                Full name: {userProfile.firstName}&nbsp;{userProfile.middleName}
                &nbsp;
                {userProfile.lastName}{" "}
              </Label>
            </div>
          </UncontrolledTooltip>
          <div className=" d-flex ">
            <div className="mx-2 ">


              <Link to={"/dashboard/call/" + userProfile.id}>
                <RoundIconButton
                  buttonColor="#00C426"
                  icon={faPhone}
                  iconSize="fa-md"
                  buttonSize="25px"
                ></RoundIconButton>
              </Link>
              <button
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                id="hospitalMapToggle"
                color="#00C426"
                icon={faSearchLocation}
                className="fa-flip-horizontal"
                onClick={() => {
                  setShowMap(true);
                  setpostalCode(userProfile?.postalCode?.code);
                }}
              />
              <UncontrolledTooltip target="hospitalMapToggle">
                Hospital Map
              </UncontrolledTooltip>
            </button>
              {/* </Link> */}
            </div>

          </div>

          
          <div>
            <Badge color={"warning"}>{userProfile.postalCode?.county}</Badge>
          </div>
        </div>
        <div className="d-flex align-items-center" style={{ flex: 1 }}>
          <FontAwesomeIcon
            // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}
            color={GenderSwitch(userProfile.gender).genderColor}
            // icon={userProfile.gender == "1" ? faMars : faVenus}
            icon={GenderSwitch(userProfile.gender).genderSymbol}
          />
        </div>


        <div className="d-flex  align-items-center" style={{ flex: 3 }}>
          <div style={{ height: "7vh", flex: 1 }}>
            <div className="flex-row">
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ flex: 3 }}
              >
                <div style={{ height: "3vh", flex: 1 }}>Email</div>
                <div style={{ height: "3vh", flex: 1 }}>

                  {userProfile.email}
                </div>


              </div>



            </div>
          </div>
        </div>

        <div className="d-flex  align-items-center" style={{ flex: 3 }}>
          <div style={{ height: "7vh", flex: 1 }}>
            <div className="flex-row">
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ flex: 3 }}
              >
                <div style={{ height: "3vh", flex: 1 }}>Postal code</div>
                <div style={{ height: "3vh", flex: 1 }}>

                  {userProfile?.postalCode?.code}
                </div>


              </div>



            </div>
          </div>
        </div>



        <div className="d-flex  align-items-center" style={{ flex: 3 }}>
          <div style={{ height: "7vh", flex: 1 }}>
            <div className="flex-row">
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ flex: 3 }}
              >
                {/* <div style={{ height: "3vh", flex: 1 }}>Band</div> */}
                <div style={{ height: "3vh", flex: 1 }}>

                  {userProfile?.candidateBand?.name
                  }
                </div>


              </div>



            </div>
          </div>
        </div>



        <div className="d-flex  align-items-center" style={{ flex: 3 }}>
          <div style={{ height: "7vh", flex: 1 }}>
            <div className="flex-row">
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ flex: 3 }}
              >
                <div style={{ height: "3vh", flex: 1 }}>Speciality</div>
                <div style={{ height: "3vh", flex: 1 }}>
                  {Array.isArray(userProfile?.candidateSpecialities)
                    ? userProfile.candidateSpecialities.map((speciality, index) => (
                      <span key={index}>{speciality.name}</span>
                    ))
                    : userProfile?.candidateSpecialities?.name}
                </div>
                <div style={{ height: "3vh", flex: 1 }}></div>
              </div>

            </div>
          </div>
        </div>

        <div className="d-flex  align-items-center" style={{ flex: 3 }}>
          <div style={{ height: "7vh", flex: 1 }}>
            <div className="flex-row">
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ flex: 3 }}
              >
                {/* <div style={{ height: "3vh", flex: 1 }}>Band</div> */}
                <div style={{ height: "3vh", flex: 1 }}>
                  <Button
                    color="success"
                    onClick={() => {
                      setStatues("INACTIVE");
                      setCandidateId(userProfile.id)
                      toggleModal();
                    }}
                  >
                    {"Dormant"}
                  </Button>
                </div>


              </div>



            </div>
          </div>
        </div>


      </div>


      {/* ))} */}

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => setCalendarShow(false)}
      >
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>



      <ModalComponent
        show={showAddRow}
        header="Update User "
        closeModal={() => setShowAddRow(false)}
      >
        <AddUser
          mode="Update"
          modalType="Document"
          closeModal={() => setShowAddRow(false)}
          rowID={showRowID1}

        />
      </ModalComponent>
      <ModalComponent
        show={showCall}
        style={{ width: '50px' }}
        className="modal-dialog modal-sm"
        closeModal={() => setShowCall(false)}
      >
        <OnboardingCall
          rowID={showRowID1}
          candidatePhoneNo={candidatePhoneNo}
          lanndLine={lanndLine}
          secondaryMobile={secondary}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>

      <ModalComponent
        show={showCallDelete}
        style={{ width: '50px' }}
        className="modal-dialog modal-sm"
        closeModal={() => setShowCallDelete(false)}
      >
        <OnboardingCallDelete
          rowID={showRowID1}
          closeModal={() => setShowCallDelete(false)}
          modalType="Dates count"
          mode="Delete"
        />
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={() => toggleM()}
      >
        <EmailNav closeModal={() => toggleM()} user={userProfile} />
      </ModalComponent>

      <ModalComponent
        show={showAddMap}
        header="Location"
        closeModal={() => setShowMap(false)}
        className="modal-dialog modal-xl"
      >
        <AddMap
          mode="individual"
          closeModal={() => setShowMap(false)}
          defaultPostalCode={postalCode}
          candidateId={userProfile?.id}
          // rowID={showRowID1}
        />
      </ModalComponent>
      <AddNoteModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          onSave={handleSaveNote}
          important={true}
        />
    </>
  );
};
export default OnboardingUnverifiedWidget;
