import React, { useEffect, useState } from "react";
import { Button, Label } from "reactstrap";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./../../authConfig";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { deleteEmail } from "./graph";
import ThreadDetails from "./ThreadDetails";
import DOMPurify from "dompurify";
import { connect } from "react-redux";
import EmailFormOutlook from "./EmailFormOutlook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faDownload,
  faFlag,
  faPaperclip,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";

const OutlookBodySection = ({
  handleBackClick,
  item,
  user,
  incomingPage,
  selectedAccount,
  emailBody,
}) => {
  const { addToast } = useToasts();
  const { instance, accounts } = useMsal();

  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showForwardForm, setShowForwardForm] = useState(false);
  const [showReplyALLForm, setShowReplyALLForm] = useState(false);

  const handleReply = () => {
    setShowReplyForm(true);
  };
  const handleForward = () => {
    setShowForwardForm(true);
  };

  const [threads, setThreads] = useState([]);

  useEffect(() => {
    RequestConversationMessageGet(item);
    function fetchAttachments() {
      // const attachments = RequestEmailGetAddAttachment(item);
      setAttachments(attachments);
    }
    fetchAttachments();
  }, [item]);

  function RequestEmailDelete() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: isEmpty(selectedAccount)
          ? accounts[0]
          : selectedAccount?.account,
      })
      .then((response) => {
        axios
          .delete("https://graph.microsoft.com/v1.0/me/messages/ " + item?.id, {
            headers: {
              Authorization: "Bearer " + response?.accessToken,
            },
          })
          .then((response) => {
            ToastCommon(true, "delete", addToast);
          })
          .catch((error) => {
            ToastCommon(true, "error", addToast);
          });
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  }

  function RequestConversationMessageGet(item) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: isEmpty(selectedAccount)
          ? accounts[0]
          : selectedAccount?.account,
      })
      .then((response) => {
        axios
          .get(
            `https://graph.microsoft.com/v1.0/me/messages?$filter=conversationId eq '${item?.conversationId}'`,
            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => {
            let array = response?.data?.value;
            array.pop();

            setThreads(array);

            response.data.value.forEach((thread, index) => {
              if (index === 0) {
                return;
              }
            });
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  const date = new Date(item?.sentDateTime);
  const formattedDate = date.toLocaleString("en-GB", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  function RequestEmailItemMoveFolder(destinationFolder) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: isEmpty(selectedAccount)
          ? accounts[0]
          : selectedAccount?.account,
      })
      .then((response) => {
        let folderId;
        switch (destinationFolder) {
          case "archive":
            folderId = "archive";
            break;
          case "trash":
            folderId = "deleteditems";
            break;
          case "inbox":
            folderId = "inbox";
            break;
          case "junkemail":
            folderId = "junkemail";
            break;
          default:
            folderId = "deleteditems";
            break;
        }
        axios
          .post(
            "https://graph.microsoft.com/v1.0/me/messages/" +
              item?.id +
              "/move",
            {
              destinationId: folderId,
            },
            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => {
            deleteEmail(response?.accessToken, folderId);
          });
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  }

  function RequestEmailItemUpdate(item, flagIconId) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: isEmpty(selectedAccount)
          ? accounts[0]
          : selectedAccount?.account,
      })
      .then((response) => {
        const newFlagStatus =
          item?.flag.flagStatus === "flagged" ? "notFlagged" : "flagged";

        axios
          .patch(
            `https://graph.microsoft.com/v1.0/me/messages/${item?.id}`,
            {
              isRead: true,
              flag: {
                flagStatus: newFlagStatus,
              },
            },
            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => {
            const flagIcon = document.getElementById(flagIconId);
            if (flagIcon) {
              flagIcon.style.color =
                newFlagStatus === "flagged" ? "#E74C3C" : "#d1d1d1";
            }
            item.flag.flagStatus = newFlagStatus;
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  const [flagStatus, setFlagStatus] = useState(item?.flag?.flagStatus);

  useEffect(() => {
    const flagIcon = document.getElementById("flag-icon");

    if (flagIcon) {
      flagIcon.style.color = flagStatus === "flagged" ? "#E74C3C" : "#d1d1d1";
    }
  }, [flagStatus]);

  const handleFlagClick = () => {
    const newFlagStatus = flagStatus === "flagged" ? "notFlagged" : "flagged";
    setFlagStatus(newFlagStatus);

    RequestEmailItemUpdate(item);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const sanitize = (html) => {
    const rejectedColorRegex = /color=["']?#?000000["']?/g;

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = DOMPurify.sanitize(html, {
      FORBID_ATTR: ["style"],
      ADD_ATTR: ["style"],
      FORBID_TAGS: ["style"],
      FORBID_STYLES: ["color"],
      ALLOWED_STYLE: {
        "text-align": [/^left$/, /^right$/, /^center$/],
      },
    });

    const sanitizedHTML = tempDiv.innerHTML.replace(rejectedColorRegex, "");
    return sanitizedHTML;
  };

  const processCIDImages = (content, attachments = []) => {
    const cidRegex = /src=["']cid:([^"']+)["']/g;

    const processedContent = content.replace(cidRegex, (match, cid) => {
      if (attachments.length > 0) {
        // Find the attachment with the matching CID and filter out attachments with duplicate names
        const uniqueAttachments = [
          ...new Map(
            attachments.map((att) => [att.name, att]) // Use filename as the unique key
          ).values(),
        ];

        const attachment = uniqueAttachments.find(
          (att) => att.contentId && att.contentId.includes(cid)
        );
        if (attachment) {
          const base64Data = attachment.contentBytes;
          const mimeType = attachment.contentType;

          return `src="data:${mimeType};base64,${base64Data}"`;
        }
      }

      return match; // Return original `cid` if no match is found
    });

    return processedContent;
  };

  // const sanitizedData = () => ({
  //   __html: sanitize(processCIDImages(item?.body.content, item?.attachments)),
  // });

  const sanitizedData = () => {
    // Extract the last reply, process CID images, and sanitize
    const latestReply = extractLastReply(emailBody);
    const processedContent = processCIDImages(latestReply, item?.attachments);

    return {
      __html: DOMPurify.sanitize(processedContent),
    };
  };

  // Function to extract the latest reply and clean up unnecessary attributes
  // Function to extract the last reply from the HTML content
  const extractLastReply = (htmlContent) => {
    // Create a temporary DOM element to parse the HTML content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Get all the <hr> elements to use as separators
    const hrElements = Array.from(tempDiv.getElementsByTagName("hr"));

    // If there are no <hr> elements, return the whole content
    if (hrElements.length === 0) {
      return htmlContent;
    }

    // Find the last <hr> element
    const lastHr = hrElements[hrElements.length - 1];

    // Get the content after the last <hr> element
    let lastReplyContent = "";
    let currentElement = lastHr.nextSibling;

    while (currentElement) {
      // Append the HTML of the current element
      lastReplyContent += currentElement.outerHTML;

      // Move to the next sibling
      currentElement = currentElement.nextSibling;
    }

    return lastReplyContent;
  };
  return (
    <div className="d-flex flex emailBody" id="content-body">
      <div
        className="d-flex flex-column flex"
        style={{ height: "auto", width: "50vh" }}
      >
        <div
          className="m-2 card"
          style={{
            backgroundColor: "#ebebeb",
            color: "#6b6161",
            whiteSpace: "break-spaces",
          }}
        >
          {/* {incomingPage === "EmailPage" ? ( */}
          <>
            <div className="px-5 d-flex align-items-end justify-content-end">
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="icon pointer mx-3 btn-raised shadow-none"
                size="lg"
                title="Back"
                onClick={handleBackClick}
              />

              <FontAwesomeIcon
                icon={faDownload}
                className="icon pointer mx-3  btn-raised shadow-none"
                size="lg"
                title="Archive"
                onClick={() => RequestEmailItemMoveFolder("archive")}
              />

              <FontAwesomeIcon
                icon={faFlag}
                className="icon pointer mx-3  btn-raised shadow-none"
                size="lg"
                style={{
                  color: flagStatus === "flagged" ? "#E74C3C" : "#d1d1d1",
                }}
                title="Flag"
                onClick={handleFlagClick}
              />

              <FontAwesomeIcon
                icon={faTrash}
                className="icon pointer mx-3  btn-raised shadow-none"
                size="lg"
                title="Trash"
                onClick={() => RequestEmailItemMoveFolder("trash")}
              />

              <FontAwesomeIcon
                icon={faBars}
                className="icon pointer mx-3  btn-raised shadow-none"
                style={{ fontSize: "16px" }}
                title="Select"
                onClick={toggleMenu}
              />
              {isOpen && (
                <div className="menu">
                  <ul>
                    <li onClick={() => RequestEmailItemMoveFolder("inbox")}>
                      Move to Inbox
                    </li>
                    <li onClick={() => RequestEmailItemMoveFolder("junkemail")}>
                      Move to Junk Email
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <br />
            <div className=" px-5 d-flex align-items-end justify-content-end ">
              <Button
                className="mr-2  "
                style={{ height: "40px", width: "100px" }}
                onClick={handleReply}
              >
                Reply
              </Button>

              <Button
                className="mr-2 "
                style={{ height: "40px", width: "100px" }}
                onClick={() => setShowReplyALLForm(true)}
              >
                ReplyAll
              </Button>
              <Button
                className="mr-2"
                style={{ height: "40px", width: "100px" }}
                onClick={handleForward}
              >
                Forward
              </Button>

              <Button
                className="mr-2"
                style={{ height: "40px", width: "170px" }}
                onClick={RequestEmailDelete}
              >
                Permanent delete
              </Button>
            </div>
          </>

          <div className="my-4 d-flex  ">
            {item.hasAttachments ? (
              <Label>
                <FontAwesomeIcon
                  icon={faPaperclip}
                  className="mx-2"
                ></FontAwesomeIcon>
                Attachment(s):
              </Label>
            ) : (
              <></>
            )}

            <span>
              <span id="attached-files-main-email" className="mx-2"></span>
            </span>
          </div>

          <div
            className="text-sm p-3 px-2 mx-2 "
            style={{
              backgroundColor: "white",
              color: "#6b6161",
              whiteSpace: "break-spaces",
            }}
          >
            <div
              className="d-flex mb-2 "
              style={{ overflow: "auto", height: "100vh", width: "100%" }}
            >
              <span
                className="avatar gd-warning w-40"
                style={{ marginLeft: "-40px" }}
              ></span>

              <span className="mx-2 ">
                <b className="font-weight-bold">{item?.subject}</b>

                <br />
                <div
                  className="d-flex flex-column"
                  style={{
                    backgroundColor: "white",
                    color: "#6b6161",
                    whiteSpace: "break-spaces",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      color: "#6b6161",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <span>
                      <span className="text-muted"> </span>
                      <span>
                        {item?.sender?.emailAddress?.name}
                        {" - "}
                        <small>{formattedDate}</small>
                        <small className="ml-2">
                          {"From <"}
                          {item?.from?.emailAddress?.address}
                          {">"}
                        </small>
                      </span>
                      <div
                        className="mt-1 p-2 ml-2 "
                        style={{ maxWidth: "100%" }}
                      >
                        {item?.body?.contentType === "html" && (
                          <div
                            dangerouslySetInnerHTML={{ __html: emailBody }} // Render email body with updated content
                          />
                        )}
                        {item?.body?.contentType === "text" && (
                          <pre
                            style={{
                              backgroundColor: "white",
                              color: "#6b6161",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {item?.body?.content}
                          </pre>
                        )}

                        {/* <span style={{ color: "white" }}>
                            {item.bodyPreview}
                          </span> */}
                        <br />
                      </div>
                      <br /> <br />
                    </span>

                    <span>
                      <span className="text-muted"> </span>
                      <br />
                      <ThreadDetails
                        threads={threads}
                        sanitizedData={sanitizedData}
                        selectedAccount={selectedAccount}
                      />
                    </span>
                  </div>
                </div>
              </span>
            </div>
            {/* <div className="emailBodyStyles text-color" />  */}
          </div>
        </div>
      </div>

      {/* <EmailComposeModal
        showForm={showReplyForm}
        closeModal={() => setShowReplyForm(false)}
        type="REPLY"
        sender={user}
        threads={threads}
        
        
      /> */}

      {showReplyForm && (
        <EmailFormOutlook
          composeType={"REPLY"}
          emailBody={item?.body}
          closeModal={() => setShowReplyForm(false)}
          from={item?.from}
          dateAndTime={item?.sentDateTime}
          subjectIncomming={item?.subject}
          cc={item?.ccRecipients}
          bcc={item?.bccRecipients}
          to={item?.toRecipients}
          email={item}
        />
      )}
      {showReplyALLForm && (
        <EmailFormOutlook
          composeType={"REPLY_ALL"}
          emailBody={item?.body}
          closeModal={() => setShowReplyALLForm(false)}
          from={item?.from}
          dateAndTime={item?.sentDateTime}
          subjectIncomming={item?.subject}
          cc={item?.ccRecipients}
          bcc={item?.bccRecipients}
          to={item?.toRecipients}
          email={item}
        />
      )}

      {showForwardForm && (
        <EmailFormOutlook
          composeType={"FORWARD"}
          emailBody={item?.body}
          closeModal={() => setShowForwardForm(false)}
          from={item?.from}
          dateAndTime={item?.sentDateTime}
          subjectIncomming={item?.subject}
          cc={item?.ccRecipients}
          bcc={item?.bccRecipients}
          to={item?.toRecipients}
          email={item}
        />
      )}

      {/* 
      <EmailComposeModal
        showForm={showForwardForm}
        closeModal={() => setShowForwardForm(false)}
        type="FORWARD"
        sender={user}
      /> */}

      <style js>{`

    
        

        
         
        input {
          width: 100%;
          padding: 8px 20px;
        }

        .demo-wrapper {
          border: 1px solid rgb(163, 163, 163);
          border-radius: 3px;
        }
        .demo-editor {
          height: 28vh;
          width: 47vw;
          color: rgb(77, 77, 80);
        }
        .close-btn {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 20px;
          font-weight: bold;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }
        
        .right-corner {
          display: flex;
          justify-content: flex-end;
      }
      .right-corner > .icon {
        margin-right: 10px /* Adjust the value as per your desired spacing */
      
    }

    .icon {
      position: relative;
    }
    
    .icon::after {
      content: attr(title);
      position: absolute;
      top: -20px; /* Adjust the distance of the tooltip from the icon */
      left: 50%; /* Adjust the position of the tooltip */
      transform: translateX(-50%);
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      font-size: 14px;
      border-radius: 4px;
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s linear;
    }
    
    .icon:hover::after {
      visibility: visible;
      opacity: 1;
    }
    .icon {
      cursor: pointer;
    }
    
    .menu {
      position: absolute;
      top: 30px;
      right: 0;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 8px;
      z-index: 999;
      
    }
    
    .menu ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    
    .menu li {
      padding: 8px;
      cursor: pointer;
      color: #000;
    }
    
    .menu li:hover {
      background-color: #f5f5f5;
    }
    .hidden-image img {
      display: none;
    }
   
    
    
    
    
    
        
       
    
      `}</style>
    </div>
  );
};

function mapStatetoProps(state) {
  return {
    user: state.auth.item,
  };
}

export default connect(mapStatetoProps, {})(OutlookBodySection);
