import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { result, forEach, filter } from "lodash";
import useDidMountEffect from "./intialRunUseEffect";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../components/toastCommon";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import {
  REGISTER_CANDIDATE,
  WARDS,
  SHIFTS,
  CREATE_BOOKING,
  BOOKING_WORKSPACE_CANDIDATELIST,
  HOSTMexxar,
  ClientLable,
  CompanyName,
} from "../../../configs/api-config";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { sendEmail } from "../../../pages/email/graph";
import outlookLogo from "../../../../src/images/Outlook-Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faHospital,
  faRandom,
  faSyncAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { sendNotification } from "../../../util/common/sendNotification";

function AddBooking(props) {
  const { register, handleSubmit, errors } = useForm();

  const {
    onCreate,
    closeModal,
    candidateId,
    dayData,
    candidateWhenClick,
    user,
  } = props;

  const MexxarApi = HOSTMexxar;
  const MexxarShift = SHIFTS;
  const MexxarCandidate = REGISTER_CANDIDATE;
  const MexxarCreateBooking = CREATE_BOOKING;
  const MexxarApiBookingWorkspace = BOOKING_WORKSPACE_CANDIDATELIST;

  const { addToast } = useToasts();

  const [check, setCheck] = useState(false);
  const [shifts, setShifts] = useState(null);
  const [likeWards, setLikeWards] = useState([]);
  const [candidates, setCandidates] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [likeClients, setLikeClients] = useState([]);
  const [currentShift, setCurrentShift] = useState(0);
  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedWard, setSelectedWard] = useState(0);
  const [dataErrorStatus, setDataErrorStatus] = useState(false);
  const [dateSelected, setDateSelected] = useState(dayData.date);
  const [showSpecialRates, setShowSpecialRates] = useState(false);
  const [previousBooking, setPreviousBooking] = useState(false);
  const [isCreatingBooking, setIsCreatingBooking] = useState(false);
  const [candidateSpecialities, setCandidateSpecialities] = useState();
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [duplicateValue, setDuplicateValue] = useState(false);
  const [matchingLikeWardsWithClient, setMatchingLikeWardsWithClient] =
    useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState(candidateId);
  const [outlookLoggedIn, setOutlookLoggedIn] = useState(false);
  const [outlookLoggedInError, setOutlookLoggedInError] = useState(false);
  const [refNo, setRefNo] = useState("");

  const onSubmit = (data) => {
    if (!refNo) {
      Alert("Automatically generate a booking reference...");
      return;
    }
    console.log("dataErrorStatus", data);
    if (!dataErrorStatus) {
      addBooking(data);
    }
  };

  const { instance, accounts } = useMsal();

  //Function : sending the confirmation email to the candidate
  const sendBookingConfirmationEmail = (
    data,
    candidateWhenClick,
    selectedClient,
    selectedWard
  ) => {
    let subject = `Booking Confirmation: ${data?.referenceNo}`;
    let body = `<p>Hello ${candidateWhenClick?.value},</p>
                <p>We are writing to confirm your booking for ${moment(
                  data?.bookingDate
                ).format(
                  "ddd, DD-MM-YYYY"
                )} with ${CompanyName}. Please find the details below:</p>
                <ul>
                    <li>Reference no: ${data?.referenceNo}</li>
                    <li>Date: ${moment(data?.bookingDate).format(
                      "ddd, DD-MM-YYYY"
                    )}</li>
                    <li>${ClientLable}: ${selectedClient?.name}</li>
                    <li>Ward: ${selectedWard?.name}</li>
                    <li>Start time: ${moment
                      .utc(data?.shiftFrom)
                      .local()
                      .format("h:mm A")}</li>
                    <li>End time: ${moment
                      .utc(data?.shiftTo)
                      .local()
                      .format("h:mm A")}</li>
                </ul>
    `;

    let to = [
      {
        id: candidateWhenClick?.id,
        value: candidateWhenClick?.email,
        label: candidateWhenClick?.value,
      },
    ];
    let cc = [];
    let bcc = [];
    let file = [];

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendEmail(response?.accessToken, to, cc, bcc, subject, body, file, user)
          .then(() => {
            ToastCommon(true, "sent", addToast);
            closeModal();
          })
          .catch(() => {});
      })
      .catch((error) => console.log(error));
  };

  const getwardsInSelectedClient = () => {
    axios
      .get(MexxarApi + "client-wards/clients/" + selectedClient.id)
      .then((res) => {
        let wardsList = [];
        forEach(res.data.body, function (ward) {
          let matchingPrefferedwards = filter(likeWards, (item) => {
            return ward?.id === item?.id;
          });

          // ...matchingPrefferedwards...
          if (matchingPrefferedwards.length > 0) {
            wardsList.push({
              id: ward.wardId,
              name: ward.wardName,
              label: ward.wardName,
            });
          }
        });

        setSelectedWard(wardsList[0]);
        setMatchingLikeWardsWithClient(wardsList);
      });
  };

  function checkOutlookLoginStatus() {
    if (accounts.length > 0) {
      // User is logged in
      setOutlookLoggedIn(true);
    } else {
      // User is not logged in
      setOutlookLoggedIn(false);
    }
  }

  const handleLoginOutLook = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        // Successful login

        if (response && response?.account) {
          setOutlookLoggedIn(true);
        } else {
          setOutlookLoggedIn(false);
          setOutlookLoggedInError(true);
        }
      })
      .catch(() => {
        // Handle login error
        setOutlookLoggedIn(false);
        setOutlookLoggedInError(true);
      });
  };

  useEffect(() => {
    // Call the function to check the login status
    checkOutlookLoginStatus();
  }, []);

  useEffect(() => {
    console.log("Duplicate Value:", duplicateValue);
  }, [duplicateValue]);

  useDidMountEffect(() => {
    getwardsInSelectedClient();
    return () => {};
  }, [selectedClient]);
  useEffect(() => {
    getDateField();
    return () => {};
  }, [dateSelected, selectedCandidates, candidateId]);
  useEffect(() => {
    getDateField();
  }, []);

  useDidMountEffect(() => {
    getData();
    return () => {};
  }, [selectedCandidates]);

  useEffect(() => {
    // getCandidates();
    getData();
    return () => {};
  }, []);

  useEffect(() => {
    if (dayData.shift) {
      setCurrentShift(dayData.shift.id);
    } else {
      setCurrentShift(0);
    }
    return () => {};
  }, [dayData]);

  const [listOfNotEligibleClients, setListOfNotEligibleClients] = useState([]);
  const [listOfEligibleClients, setListOfEligibleClients] = useState([]);

  const getData = () => {
    if (candidateId === undefined && selectedCandidates?.length === 0) {
      axios.get(MexxarShift).then((res3) => {
        setShifts(res3.data.body);
      });
    } else {
      axios
        .all([
          axios.get(MexxarShift),
          axios.get(MexxarCandidate + "/" + selectedCandidates),
        ])
        .then(
          axios.spread((res1, res2) => {
            const preferedClient = [];
            const activePreferredClients =
              res2.data.body[0].preferredClients.filter(
                (client) => client.status === "ACTIVE"
              );
            preferedClient.push(
              ...activePreferredClients.map((client) => ({
                id: client.id,
                name: client.name,
              }))
            );

            let specialities = [];
            res2.data.body[0].candidateSpecialities.forEach((speciality) => {
              specialities.push({
                id: speciality.id,
                name: speciality.name,
              });
            });

            setShifts(res1.data.body);
            setLikeWards(res2.data.body[0].likeWards);
            setLikeClients(preferedClient);
            setCandidateSpecialities(specialities);

            if (preferedClient.length > 0) {
              setSelectedClient(preferedClient[0]);
            }
          })
        )
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getDateField = () => {
    axios
      .get(
        MexxarApiBookingWorkspace +
          "?startDate=" +
          moment(dateSelected).format("YYYY/MM/DD") +
          "&endDate=" +
          moment(dateSelected).add(1, "days").format("YYYY/MM/DD")
      )
      .then((res) => {
        const body = res.data.body;
        console.log("Response Data:", body);

        let listOfCandidates = [];
        let eligibleClients = [];
        let notEligibleClients = [];

        for (let i = 0; i < body.length; i++) {
          const candidate = body[i].candidate;
          const candidateName = candidate.firstName + " " + candidate.lastName;
          listOfCandidates.push({
            value: candidateName,
            label: candidateName,
            id: candidate.id,
          });

          const bookingEligibilities = candidate.bookingEligibilities || [];
          bookingEligibilities.forEach((eligibility) => {
            const clientData = {
              clientId: eligibility.clients.id,
              clientName: eligibility.clients.name,
              candidateId: candidate.id,
              candidateName: candidateName,
            };
            if (eligibility.bookingEligibleStatus === "ELIGIBLE") {
              eligibleClients.push(clientData);
            } else if (eligibility.bookingEligibleStatus === "NOT_ELIGIBLE") {
              notEligibleClients.push(clientData);
            }
          });
        }

        setCandidates(listOfCandidates);
        setListOfEligibleClients(eligibleClients);
        setListOfNotEligibleClients(notEligibleClients);

        // console.log("List of Candidates:", listOfCandidates);
        console.log("List of Eligible Clients:", eligibleClients);
        console.log("List of Not Eligible Clients:", notEligibleClients);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log("like Clients", likeClients);

  const [notEligibleClientSelected, setNotEligibleClientSelected] =
    useState(false);

  const filteredClients = likeClients.filter(
    (client) =>
      listOfEligibleClients.some(
        (eligible) =>
          eligible.clientId === client.id &&
          eligible.candidateId === candidateId
      ) ||
      listOfNotEligibleClients.some(
        (notEligible) =>
          notEligible.clientId === client.id &&
          notEligible.candidateId === candidateId
      )
  );

  const options = filteredClients.map((client) => {
    const isNotEligible = listOfNotEligibleClients.some(
      (el) => el.clientId === client.id && el.candidateId === candidateId
    );
    const isEligible =
      !isNotEligible &&
      listOfEligibleClients.some(
        (el) => el.clientId === client.id && el.candidateId === candidateId
      );

    return {
      value: client.id,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon icon={faHospital} style={{ marginRight: "5px" }} />
          {client.name}
          {isEligible && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ color: "green", marginLeft: "5px" }}
            />
          )}
          {isNotEligible && (
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ color: "red", marginLeft: "5px" }}
            />
          )}
        </div>
      ),
      isDisabled: isNotEligible,
      isNotEligible: isNotEligible,
    };
  });

  const addBooking = async (data) => {
    if (duplicateValue) {
      // Prevent form submission if duplicate value is detected
      ToastCommon(
        true,
        "duplicate",
        addToast,
        "The reference number already exists. Please change it before submitting."
      );
      return;
    }
    console.log(refNo);
    if (!refNo) {
      console.log("Ref No is missing");
      setDuplicateValue(true);
    } else {
      setDuplicateValue(false);
    }

    if (isCreatingBooking) return;

    setDisableCreateButton(true);
    setIsCreatingBooking(true);

    try {
      if (
        selectedClient &&
        options.some(
          (option) => option.value === selectedClient.id && option.isNotEligible
        )
      ) {
        setNotEligibleClientSelected(true);
        return;
      }

      // Prepare booking data
      const BStatus = check ? 1 : 0;
      const shiftStartingDate = moment(data.Booking_Date).format("YYYY-MM-DD");
      const shiftStartingTime = moment(data.Start_Time, "HH:mm").format(
        "HH:mm:ss"
      );
      const shiftEndingTime = moment(data.End_Time, "HH:mm").format("HH:mm:ss");
      const shiftSTime = moment(
        `${shiftStartingDate} ${shiftStartingTime}`
      ).toDate();
      const shiftETime = moment(
        `${shiftStartingDate} ${shiftEndingTime}`
      ).toDate();
      const currentDate = moment().toDate();

      const arrangData = {
        bookingDate: shiftStartingDate,
        bookingStatus: BStatus,
        bookingAttendedStatus: 4,
        createdDate: currentDate,
        referenceNo: data.Ref_No,
        candidateId: selectedCandidates,
        clientsId: selectedClient ? Number(selectedClient.id) : null,
        specialityId: Number(data.specialityId),
        shiftId: Number(data.shiftId),
        wardId: Number(data.departmentId),
        // shiftTo: shiftETime,
        // shiftFrom: shiftSTime,
        shiftTo: moment(`${shiftStartingDate} ${endTime}`, "YYYY-MM-DD HH:mm").toDate(), 
        shiftFrom: moment(`${shiftStartingDate} ${startTime}`, "YYYY-MM-DD HH:mm").toDate(), 
        bonus: data.bonus !== undefined ? Number(data.bonus) : 0,
        upLift: data.upLift !== undefined ? Number(data.upLift) : 0,
        totalHours:
          data.totalHours !== undefined ? parseInt(data.totalHours, 10) : 0,
        breakTime:
          data.breakTime !== undefined ? parseInt(data.breakTime, 10) : 0,
      };
      console.log("Start Time:", startTime);
      console.log("End Time:", endTime);
      
      const refResponse = await axios.get(
        `${HOSTMexxar}bookings/reference/${data.Ref_No}`
      );
      
      if (refResponse?.data?.message === "Not Existing") {
        const createResponse = await axios.post(
          MexxarCreateBooking,
          arrangData
        );
        if (createResponse?.data?.status === "success") {
          closeModal();

          if (!previousBooking) {
            sendBookingConfirmationEmail(
              arrangData,
              candidateWhenClick,
              selectedClient,
              selectedWard
            );
          }
          const formattedShiftSTime = moment(shiftSTime).format("dddd, MMMM D, YYYY, h:mm A");
          const formattedShiftETime = moment(shiftETime).format("h:mm A");

          sendNotification({
            notificationType: "BOOKING",
            title: `New Booking Created: Reference Number ${data.Ref_No}`,
            content: `A new booking has been successfully created for ${shiftStartingDate}.\n` +
                     `The scheduled shift will take place from:\n` +
                     `${startTime} - ${endTime}.`,
            candidateId: selectedCandidates,
            startDate: shiftStartingDate,
            addToast: addToast,
          });
          

          ToastCommon(true, "success", addToast);
          onCreate(data);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      } else {
        console.log("Existing");
        setDuplicateValue(true);
        ToastCommon(true, "duplicate", addToast);
      }
    } catch (err) {
      console.error("Error creating booking:", err);
      ToastCommon(true, "duplicate", addToast);
    } finally {
      // Re-enable the create button and reset the guard clause
      setDisableCreateButton(false);
      setIsCreatingBooking(false);
    }
  };

  const [isGenerating, setIsGenerating] = useState(false);

  const generateUniqueRefNo = async () => {
    try {
      setIsGenerating(true);

      const randomRefNo = Math.floor(
        3000000000 + Math.random() * 7000000000
      ).toString();

      const refResponse = await axios.get(
        `${HOSTMexxar}bookings/reference/${randomRefNo}`
      );
      if (refResponse?.data?.message === "Existing") {
        generateUniqueRefNo();
      } else {
        setRefNo(randomRefNo);
        setDuplicateValue(false);
      }
    } catch (error) {
      // console.error("Error generating reference number:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 5) {
        const time = `${String(hour).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        times.push(time);
      }
    }
    return times;
  };
  
  const timeOptions = generateTimeOptions();
  const [startTime, setStartTime] = useState(""); 
  const [endTime, setEndTime] = useState(""); 
  const [isValid, setIsValid] = useState(true); 
  

  const handleTimeChange = (e, setTime) => {
    let { value } = e.target;
  
  
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
  
   
    if (value.length === 2 && !value.includes(':')) {
      value = `${value}:`;
    }
  
   
    if (value.length >= 3 && parseInt(value.substring(0, 2), 10) > 23) {
      value = `23${value.substring(2)}`;
    }
  
   
    if (value.length === 5 && parseInt(value.substring(3), 10) > 59) {
      value = `${value.substring(0, 3)}59`;
    }
  
 
    setTime(value);
  
 
    const valid = validateTimeInput(value);
    setIsValid(valid);
  };
  
  const validateTimeInput = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timeRegex.test(time);
  };
  
  
  

  
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label sm={3}>Ref No</Label>

          <Col sm={8} className="mb-2">
            <Input
              type="text"
              placeholder="Ref No"
              name="Ref_No"
              value={refNo}
              innerRef={register({
                required: "This field is required!",
                maxLength: {
                  value: 150,
                  message: "This field only allows up to 150 characters",
                },
              })}
              onChange={(e) => {
                const newValue = e.target.value;
                setRefNo(newValue);
                if (duplicateValue) setDuplicateValue(false);
              }}
            />
          </Col>

          <Col sm={1} className="d-flex justify-content-start">
            <Button
              color="link"
              onClick={() => {
                generateUniqueRefNo();
                setIsGenerating(true);
              }}
              style={{ padding: 0, border: "none" }}
              id="random"
            >
              <FontAwesomeIcon
                icon={faSyncAlt}
                size="lg"
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              />
            </Button>

            <UncontrolledTooltip target="random">
              <small>Generate random number</small>
            </UncontrolledTooltip>
          </Col>

          {duplicateValue && (
            <>
              <Col sm={12}>
                <Alert color="danger">
                  The reference number already exists in the CRM. Please use a
                  different reference number or generate a random one above.
                </Alert>
              </Col>
            </>
          )}

          {!duplicateValue && !isGenerating && (
            <Col sm={12}>
              <Alert color="info">
                Automatically generate a booking reference if the shift does not
                have a reference number or if the reference already exists in
                the CRM.
              </Alert>
            </Col>
          )}
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Date</Label>
          <Col sm={8}>
            <Input
              defaultValue={
                dayData.date != undefined || dayData.date != null
                  ? moment(dayData.date).format("YYYY-MM-DD")
                  : ""
              }
              type="date"
              invalid={errors.date || dataErrorStatus}
              placeholder="Date"
              name="Booking_Date"
              innerRef={register}
              onChange={(e) => {
                setDataErrorStatus(
                  moment(e.target.value).format("l") == moment().format("l")
                    ? false
                    : moment(e.target.value).isBefore()
                );
                setDateSelected(e.target.value);
              }}
            />
            {dataErrorStatus && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">Invalid Date</li>
              </ul>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Shift type</Label>
          <Col sm={8}>
            <Input
              type="select"
              id="shiftId"
              name="shiftId"
              value={currentShift}
              innerRef={register({
                required: "Shift type is required",
                validate: (value) =>
                  value !== "0" || "Please select a shift type",
              })}
              onChange={(e) => setCurrentShift(e.target.value)}
            >
              <option value="0">Select</option>
              {shifts &&
                shifts.map((item, id) => (
                  <option value={item.id} key={id}>
                    {item.name}
                  </option>
                ))}
            </Input>
          </Col>
        </FormGroup>

      
        <FormGroup row>
  <Label sm={3}>Start Time</Label>
  <Col sm={8}>
    <Input
      list="time-options"
      type="text"
      name="Start_Time"
      placeholder="Enter time (HH:MM)"
      value={startTime}
      onChange={(e) => handleTimeChange(e, setStartTime)}
    />

    <datalist id="time-options">
      {timeOptions.map((time) => (
        <option key={time} value={time}>
          {time}
        </option>
      ))}
    </datalist>
  </Col>
</FormGroup>

<FormGroup row>
  <Label sm={3}>End Time</Label>
  <Col sm={8}>
    <Input
      list="time-options"
      type="text"
      name="End_Time"
      placeholder="Enter time (HH:MM)"
      value={endTime}
      onChange={(e) => handleTimeChange(e, setEndTime)}
    />

    <datalist id="time-options">
      {timeOptions.map((time) => (
        <option key={time} value={time}>
          {time}
        </option>
      ))}
    </datalist>
  </Col>
</FormGroup>

        <FormGroup row>
          <Col sm={3}>
            <Label>Tag Candidate</Label>
          </Col>
          <Col sm={8}>
            <Select
              defaultValue={candidateWhenClick ? candidateWhenClick : ""}
              name="employeeId"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setSelectedCandidates(data.id)}
              isSearchable={false}
              // options={candidates}
              // innerRef={register({
              //   required: {
              //     value: true,
              //     message: "This field is required!",
              //   },
              // })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Department{" "}
            <FontAwesomeIcon
              id="department"
              className="text-warning"
              icon={faExclamationCircle}
            ></FontAwesomeIcon>
          </Label>
          <Col sm={8}>
            <Input
              type="select"
              id="departmentId"
              name="departmentId"
              //   invalid={errors.wardId}

              onChange={(e) => {
                const selectedOption = matchingLikeWardsWithClient.find(
                  (item) => item.id === parseInt(e.target.value)
                );

                setSelectedWard(selectedOption);
              }}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              {matchingLikeWardsWithClient &&
                matchingLikeWardsWithClient.map((item, id) => {
                  return (
                    <option value={item.id} key={id}>
                      {item.name}
                    </option>
                  );
                })}
            </Input>
            <UncontrolledTooltip target={"department"}>
              <small>
                Note: This field will only populate departments for below
                selected {ClientLable} if the department is in candidate's
                likeWard list.
              </small>
            </UncontrolledTooltip>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Candidate Specialities</Label>
          <Col sm={8}>
            <Input
              type="select"
              id="specialityId"
              name="specialityId"
              // invalid={errors.wardId}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              {candidateSpecialities &&
                candidateSpecialities.map((item, id) => {
                  return (
                    <option value={item.id} key={id}>
                      {item.name}
                    </option>
                  );
                })}
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>{ClientLable}</Label>
          <Col sm={8}>
            <Select
              id="clientId"
              name="clientId"
              value={options.find(
                (option) => option.value === selectedClient?.id
              )}
              onChange={(selectedOption) => {
                setSelectedClient({
                  id: selectedOption.value,
                  name: selectedOption.label.props.children[1],
                });

                setNotEligibleClientSelected(false);
              }}
              options={options}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isInvalid={!!errors.clientId}
            />

            {notEligibleClientSelected && (
              <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                This client is not eligible for booking
              </div>
            )}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>Do Not Send Confirmation</Label>
          <Col sm={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="previousBooking"
                  value={previousBooking}
                  onClick={() => setPreviousBooking(!previousBooking)}
                />
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Special Booking</Label>
          <Col sm={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="type-radio"
                  // value="speacialBookings"
                  defaultChecked={showSpecialRates}
                  onClick={() => setShowSpecialRates(!showSpecialRates)}
                />
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>

        {showSpecialRates ? (
          <FormGroup row>
            <Label sm={3}>Special Rate (£)</Label>

            <Col sm={4}>
              <Input
                type="text"
                placeholder="Up lift"
                name="upLift"
                invalid={errors.upLift}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please input a number!",
                  },
                })}
              />{" "}
              {errors.upLift && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.upLift?.message}
                  </li>
                </ul>
              )}
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                placeholder="Bonus"
                name="bonus"
                invalid={errors.bonus}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please input a number!",
                  },
                })}
              />{" "}
              {errors.bonus && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.bonus?.message}
                  </li>
                </ul>
              )}
            </Col>
          </FormGroup>
        ) : null}

        <FormGroup row>
          <Label sm={3}>Total Hours</Label>
          <Col sm={8}>
            <Input
              type="number"
              placeholder="Total Hours"
              name="totalHours"
              innerRef={register}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Break Minutes</Label>
          <Col sm={8}>
            <Input
              type="number"
              placeholder="Break Minutes"
              name="breakTime"
              innerRef={register}
            />
          </Col>
        </FormGroup>

        {!outlookLoggedIn && !previousBooking && (
          <Alert
            className="d-flex align-items-center justify-content-center flex-column "
            color="danger"
          >
            <p>
              To send a booking confirmation via email, kindly ensure that you
              are logged in first
            </p>
            <img src={outlookLogo} style={{ height: 100 }}></img>
            <u className="text-info pointer ml-4" onClick={handleLoginOutLook}>
              Login
            </u>
          </Alert>
        )}

        <div className="d-flex justify-content-end">
          {previousBooking && (
            <Button
              className="mr-2"
              color="success"
              type="submit"
              disabled={disableCreateButton}
            >
              Create
            </Button>
          )}

          {!previousBooking && (
            <Button
              className="mr-2"
              color="success"
              type="submit"
              disabled={
                disableCreateButton || !outlookLoggedIn || duplicateValue
              }
            >
              Create
            </Button>
          )}
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.auth.item,
  };
}

export default connect(mapStateToProps, {})(AddBooking);
