import Axios from "axios";
import React, { useEffect, useState } from "react";
import "./hospitalsMap.style.css";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faClock, faCar } from "@fortawesome/free-solid-svg-icons";
import {
  REGISTER_CANDIDATE,
  POSTAL_CODES,
  CLIENTS,
  GoogleMapKey,
  ClientLable,
} from "../../configs/api-config";
import SliderComponent from "../../components/Slider/silder";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { isNull, filter, forEach } from "lodash";
import loadingAnimation from "../../../src/images/puff.svg";
import { Alert, Label } from "reactstrap";
import {
  consumeCredits,
  getCredits,
  getTransactions,
} from "../../util/common/creditTransactions";

const HospitalsMap = (props) => {
  const { addToast } = useToasts();
  const { candidateId, postalCode, enable, mode } = props;

  const MexxarApi = REGISTER_CANDIDATE;
  const MexxarApiClients =
    CLIENTS +
    "/filter?offset=100&page=1" +
    `${postalCode?.county && "&pc_county=" + postalCode?.county}`;
  const MexxarApiPostal = POSTAL_CODES;
  const [places, setPlaces] = useState([]);
  const [finalResults, setFinalResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [longitude, setLongitude] = useState(postalCode?.longitude);
  const [latitude, setLatitude] = useState(postalCode?.latitude);
  const [incomingRadius, setIncomingRadius] = useState(5);
  const [zoom, setZoom] = useState(11);
  const [clients, setClients] = useState(null);
  const [hoverItemName, setHoverItemName] = useState(null);
  const [creditError, setCreditError] = useState({ bool: false, message: "" });

  const AnyReactComponent = ({ text }) => (
    <div>
      {hoverItemName === text.toUpperCase() ? (
        <FontAwesomeIcon
          size="3x"
          color="#e91e63"
          icon={faHospital}
        ></FontAwesomeIcon>
      ) : (
        <FontAwesomeIcon
          size="2x"
          color="#458AF7"
          icon={faHospital}
        ></FontAwesomeIcon>
      )}
    </div>
  );

  const getLongitudeLatitude = () => {
    if (isNull(postalCode)) {
      setLongitude(null);
      setLatitude(null);
    } else {
      setLongitude(Number(postalCode?.longitude));
      setLatitude(Number(postalCode?.latitude));
    }
  };

  const getPlaces = () => {
    setLoading(true);
    Axios.get(
      mode === "individual"
        ? `${MexxarApi}/${candidateId}/nearest-clients?radius=${
            incomingRadius * 1000
          }`
        : `${MexxarApi}/nearest-clients?radius=${
            incomingRadius * 1000
          }&postalCode=${postalCode?.code}`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.results !== null) {
          let dests = "";
          let fin = [];
          let A = [];
          let B = [];
          res.data.results.forEach((item) => {
            A = filter(clients, (client) => {
              return item.name.toUpperCase() === client.value.toUpperCase();
            });

            if (A.length > 0) {
              dests += `${item.geometry.location.lat},${item.geometry.location.lng}|`;
              fin.push({
                name: A.length > 0 ? A[0].value : null,
                time: null,
                far: null,
                marketId: item.id,
              });
              B.push(item);
            }
          });

          setPlaces(B);
          getMiles(dests, fin);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  const getMiles = (desti, fin) => {
    consumeCredits(1, "CLIENT_MAP") //consuming cretis
      .then((result) => {
        if (result?.status === "Failed") {
          setCreditError({ bool: true, message: result?.message });
        } else if (result?.status === "Success") {
          setCreditError({ bool: false, message: "" });
        }
      })
      .catch((err) => {
        console.error("Error consuming credits:", err);
      });

    Axios.get(
      mode === "individual"
        ? encodeURI(
            `${MexxarApi}/${candidateId}/nearest-clients-distance?destinations=${desti}`
          )
        : encodeURI(
            `${MexxarApi}/nearest-clients-distance?destinations=${desti}&postalCode=${postalCode?.code}`
          )
    ).then((res) => {
      if (res.data.rows.length > 0) {
        let data = [...fin];
        res.data.rows[0].elements.forEach((item, i) => {
          data[i].time = item.duration.text;
          data[i].far = item.distance.text;
        });

        let tempData = [];
        forEach(data, function (item) {
          if (item.name != null) {
            tempData.push(item);
          }
        });
        setFinalResults(tempData);
      }
    });
  };

  const getClients = () => {
    axios
      .get(MexxarApiClients)
      .then((res) => {
        let tempArray = [];
        forEach(res.data.body.content, function (item) {
          tempArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });

        setClients(tempArray);
      })
      .catch((err) => {
        // Handle error
      });
  };

  useEffect(() => {
    if (enable) {
      getClients();
      getLongitudeLatitude();
    }
    return () => {};
  }, [enable, postalCode, incomingRadius]);

  useDidMountEffect(() => {
    getCredits()
      .then((result) => {
        let creditResponse = result?.response;
        const clientMapCredits = creditResponse?.body?.filter(
          (item) => item.creditServiceType === "CLIENT_MAP"
        );
        if (clientMapCredits?.[0].creditBalance > 0) {
          setCreditError({
            bool: false,
            message: "",
            creditBalance: clientMapCredits?.[0]?.creditBalance,
          });
          if (enable) {
            getPlaces();
          }
        } else {
          setCreditError({
            bool: true,
            message: "Credit error",
            creditBalance: 0,
          });
        }
      })
      .catch((err) => {
        console.error("Error consuming credits:", err);
      });

    return () => {};
  }, [clients]);

  const mouseEnter = (key, childProps) => {
    setHoverItemName(childProps.text.toUpperCase());
  };

  const mouseLeave = (key, childProps) => {
    setHoverItemName(null);
  };

  return (
    <div className="card">
      {creditError.bool ? (
        <Alert color="danger">
          Please contact Mexxar to Recharge
          <br />
          Error: {creditError?.message} | Credit Balance:{" "}
          {creditError?.creditBalance}{" "}
        </Alert>
      ) : (
        ""
      )}
      {enable ? (
        <>
          <div className="mt-4" style={{ height: 400, width: "auto" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: GoogleMapKey }}
              center={{ lat: latitude, lng: longitude }}
              onChildMouseEnter={mouseEnter}
              onChildMouseLeave={mouseLeave}
              defaultZoom={zoom}
            >
              {places.map((item, i) => (
                <AnyReactComponent
                  key={i}
                  lat={item.geometry.location.lat}
                  lng={item.geometry.location.lng}
                  text={item.name}
                />
              ))}
            </GoogleMapReact>
          </div>
          <SliderComponent onStop={(data) => setIncomingRadius(data)} />
          {finalResults?.length > 0 && (
            <div
              style={{
                maxHeight: 700,
                width: 400,
                overflowY: "scroll",
                marginTop: "2rem",
              }}
            >
              {finalResults.map((item, i) => (
                <div
                  key={i}
                  className={`p-1 d-flex ${
                    hoverItemName === item.name.toUpperCase()
                      ? "bg-primary"
                      : null
                  }`}
                >
                  <div className="py-2" style={{ width: "70px" }}>
                    <FontAwesomeIcon
                      color="#31c971"
                      icon={faClock}
                      className="mr-1"
                    />
                    <small>{item.time}</small>
                  </div>
                  <div className="py-2" style={{ width: "70px" }}>
                    <FontAwesomeIcon color="#f54394" icon={faCar} />
                    <small>{item.far}</small>
                  </div>
                  <div className="py-2" style={{ width: "20px" }}>
                    <FontAwesomeIcon color="#458AF7" icon={faHospital} />
                  </div>
                  <div className="py-2" style={{ width: "230px" }}>
                    <small>{item.name}</small>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center justify-items-center">
          <Label className="text-center p-3 d-flex align-items-center justify-content-center">
            The map is disabled. Please turn it on to see the Hospitals around
            the area
          </Label>
        </div>
      )}
      {loading && (
        <div className="d-flex justify-content-center justify-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }} alt="Loading" />
          </div>
        </div>
      )}
    </div>
  );
};

export default HospitalsMap;
